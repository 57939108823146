import { URI_PATH } from "../utils/constants"

const API_PREFIX =  URI_PATH + '/api';

const user = {
    getUserData: `${API_PREFIX}/user/action/getUserData`,
    updateMine: `${API_PREFIX}/user/action/updateMine`,
    changePassword: `${API_PREFIX}/user/action/changePassword`,
    getListLogUser: `${API_PREFIX}/user/action/getListLogUser`,
    getDataTooltipUser: `${API_PREFIX}/user/action/getDataTooltipUser`,
    getBankData: `${API_PREFIX}/user/action/getBankData`,
}

const authentication = {
    login: `${API_PREFIX}/authentication/action/login`,
}

const product = {
    getListProduct: `${API_PREFIX}/product/action/getListProduct`,
    getListProductLike: `${API_PREFIX}/product/action/getListProductLike`,
    getDataProduct: `${API_PREFIX}/product/action/getDataProduct`,
    actionLikeProduct: `${API_PREFIX}/product/action/actionLikeProduct`,
}

const admin = {
    updateProduct: `${API_PREFIX}/admin/product/updateProduct`,
    getListDepositAdmin: `${API_PREFIX}/admin/deposit/getListDeposit`,
    confirmDepositAdmin: `${API_PREFIX}/admin/deposit/confirm`,
    getListOrderAdmin: `${API_PREFIX}/admin/order/getListOrderAdmin`,
    changeStatusOrder: `${API_PREFIX}/admin/order/changeStatusOrder`,
    getListUserAdmin: `${API_PREFIX}/admin/user/getListUserAdmin`,
    toggleBlockUserAdmin:  `${API_PREFIX}/admin/user/toggleBlockUserAdmin`,
    resetPasswordAdmin: `${API_PREFIX}/admin/user/resetPasswordAdmin`,
    updateUserAdmin: `${API_PREFIX}/admin/user/updateUserAdmin`,
    getListUserChatAdmin: `${API_PREFIX}/admin/chat/getListUserChatAdmin`,
    getCountAllAdmin: `${API_PREFIX}/admin/user/getCountAllAdmin`,
    changeBank: `${API_PREFIX}/admin/user/changeBank`,
    changeRatioBalance: `${API_PREFIX}/admin/config/changeRatioBalance`,
}

const deposit = {
    deposit: `${API_PREFIX}/deposit/action/deposit`,
    withdraw: `${API_PREFIX}/deposit/action/withdraw`,
}

const order = {
    orderBalance: `${API_PREFIX}/order/action/orderBalance`,
    orderCk: `${API_PREFIX}/order/action/orderCk`,
    order: `${API_PREFIX}/order/action/order`,
}

const chat = {
    sendChat: `${API_PREFIX}/chat/action/sendChat`,
    getListChat: `${API_PREFIX}/chat/action/getListChat`,
    sendAdvise: `${API_PREFIX}/chat/action/sendAdvise`,
}

const cart = {
    addToCart: `${API_PREFIX}/cart/action/addToCart`,
    getCartInfo: `${API_PREFIX}/cart/action/getCartInfo`,
    updateProductCart: `${API_PREFIX}/cart/action/updateProductCart`,
    deleteProductCart: `${API_PREFIX}/cart/action/deleteProductCart`,
}

const config = {
    getListConfig: `${API_PREFIX}/config/action/getListConfig`,
}

export const END_POINTS = {
    ...user,
    ...authentication,
    ...product,
    ...admin,
    ...deposit,
    ...order,
    ...chat,
    ...cart,
    ...config,
}