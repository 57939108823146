import { Button, Modal } from "antd";
import { COST_TYPE, FACEBOOK_URL, MESSAGES, PROP, RESPONSE_CODE, URI_PATH } from "../../utils/constants";
import { connect } from "react-redux";
import product from "../../models/product";
import { array_move, getAvatar } from "../../utils/common";
import Cost from "../common/Cost";
import { IMAGES } from "../../assets";
import FsLightbox from 'fslightbox-react';
import { useRef, useState } from "react";
import { ratioBalanceSelector, userDataSelector } from "../../redux/selectors/user";
import models from "../../models";
import { showNotification } from "../../utils/notification";
import { sendAdviseApi } from "../../apis/chat";
import { userActionCreators } from "../../redux/actions/user";
import Icon from 'react-fontawesome'
import { actionLikeProductApi } from "../../apis/product";
import user from "../../models/user";
import Messenger from "../common/Messenger";

interface Props {
    showModal: boolean;
    onClose: () => void;
    productSelect: product.Product;
    onClickAddToCart: () => void;
    onClickLike: (productId: string, action: number) => void;
    toggleModalChat: typeof userActionCreators.toggleModalChat;
    toggleModalLogin: typeof userActionCreators.toggleModalLogin;
    userData: user.User;
    ratioBalance: string;
}

const ModalProduct = (props: Props) => {
    const { showModal, onClose, productSelect, onClickAddToCart, toggleModalChat, onClickLike, userData, toggleModalLogin, ratioBalance } = props;
    const images = productSelect.product_id ? [...productSelect.images?.map(item => getAvatar(item.asset_data)), getAvatar(productSelect.image)] : []
    const [imagesShow, setImagesShow] = useState<any[]>([])

    const [showPreviewImage, setShowPreviewImage] = useState<boolean>(false)

    const isPressRef = useRef(false)

    const hidePreviewImage = () => {
        setShowPreviewImage(false)
    }

    const openPreviewImage = (key: number) => () => {
        setImagesShow(array_move(images, key, 0))
        setShowPreviewImage(true)
    }

    const handleSendAdvise = async() =>{
        try {
            if(!userData.user_id){
                // return toggleModalLogin()
                return window.open(FACEBOOK_URL, '_blank')
            }
            if(!isPressRef.current){
                isPressRef.current = true;
                const response = await sendAdviseApi({product_id: productSelect.product_id})
                isPressRef.current = false;
                if(response.code === RESPONSE_CODE.SUCCESS){
                    toggleModalChat()
                }
                else {
                    showNotification(response.message, response.code)
                }
            }
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
            isPressRef.current = false;
        }
    }

    const handleClickLike = async() =>{
        try {
            if(!userData.user_id){
                // return toggleModalLogin()
                return window.open(FACEBOOK_URL, '_blank')
            }
            if(!isPressRef.current){
                const action = productSelect.product_like_id ? 0 : 1
                isPressRef.current = true;
                const response = await actionLikeProductApi({product_id: productSelect.product_id, action})
                isPressRef.current = false;
                showNotification(response.message, response.code)
                if(response.code === RESPONSE_CODE.SUCCESS){
                    onClickLike(productSelect.product_id, action)
                }
            }
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
            isPressRef.current = false;
        }
    }

    return (
        <Modal
            {...PROP.MODAL}
            open={showModal}
            onCancel={onClose}
            width='100vh'
        >
            <div className="item-center">
                <img
                    alt="product-img-select"
                    src={getAvatar(productSelect.image)}
                    className="product-view-img-select"
                    onClick={openPreviewImage(images.length - 1)}
                />
            </div>
            <div className='sub-image-product-container'>
                {productSelect.images?.map((item, key) =>
                    <div className='relative' key={key}>
                        <img
                            alt='sub-img'
                            className='sub-img-select pointer'
                            src={getAvatar(item.asset_data)}
                            onClick={openPreviewImage(key)}
                        />
                    </div>
                )}
            </div>
            <div className="box-detail-product-container">
                <p className='text-info-product'>{productSelect.info}</p>
            </div>
            <div className="action-bottom-product-container">
                <div>
                    <Cost textClass="text-large" type={COST_TYPE.VN} price={productSelect.balance} />
                    <Cost textClass="text-large" type={COST_TYPE.JP} price={productSelect.balance_jp} />
                </div>
                <div className="flex align-center">
                {userData.user_id ?
                    <>
                    <Icon
                        name={productSelect.product_like_id ? 'heart' : 'heart-o'}
                        className="icon-like-product mgr-2"
                        onClick={handleClickLike}
                    />
                    <img
                        alt="chat-img"
                        className="chat-icon-modal"
                        src={IMAGES.CHAT}
                        onClick={handleSendAdvise}
                    />
                    <Button
                        className="outline mgl-2"
                        onClick={onClickAddToCart}
                    >
                        Thêm vào giỏ hàng
                    </Button>
                    </>
                :
                <Messenger />
                }
                    
                </div>
            </div>
            {showPreviewImage &&
                <FsLightbox
                    toggler={showPreviewImage}
                    sources={imagesShow}
                    openOnMount={true}
                    onClose={hidePreviewImage}
                    key={0}
                    type='image'
                />
            }
        </Modal>
    )
}

const mapStateToProps = (state: models.GlobalState) =>({
    userData: userDataSelector(state),
    ratioBalance: ratioBalanceSelector(state)
})

const mapDispatchToProps = {
    toggleModalLogin: userActionCreators.toggleModalLogin,
    toggleModalChat: userActionCreators.toggleModalChat,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalProduct)