import React, { useState } from "react"
import ModalLogin from "../../modal/ModalLogin"
import models from "../../../models"
import { showModalDepositSelector, showModalLoginSelector, userDataSelector } from "../../../redux/selectors/user"
import { connect } from "react-redux"
import user from "../../../models/user"
import { IMAGES } from "../../../assets"
import { userActionCreators } from "../../../redux/actions/user"
import { ROUTES } from "../../../utils/constants"
import ModalLogout from "../../modal/ModalLogout"
import ModalDeposit from "../../modal/ModalDeposit"
import { RouteComponentProps, useLocation, withRouter } from "react-router-dom"
import Chat from "../../chat/Chat"
import Icon from 'react-fontawesome'

interface Props extends RouteComponentProps {
    children: React.ReactNode;
    userData: user.User;
    showModalDeposit: boolean;
    showModalLogin: boolean;
    toggleModalDeposit: typeof userActionCreators.toggleModalDeposit;
    toggleModalLogin: typeof userActionCreators.toggleModalLogin;
    getUserData: typeof userActionCreators.getUserData;
}

const UserLayout = React.memo((props: Props) => {
    const { userData, showModalDeposit, toggleModalDeposit, showModalLogin, toggleModalLogin, getUserData } = props;

    const route = useLocation();

    const [showModalLogout, setShowModalLogout] = useState(false)

    const isLogined = userData.user_id;

    const toggleShowModalLogout = (e: React.MouseEvent<HTMLElement> | null = null) => {
        e?.stopPropagation()
        setShowModalLogout(!showModalLogout)
    }

    const handleChangeRoute = (router: string) => () => {
        getUserData();
        props.history.push(router)
    }

    const generateClassSelected = (routeSelect: string) => {
        return route.pathname === routeSelect ? 'bold text-large' : ''
    }

    return (
        <div className="app-content">
            <div className="header-container">
                <div className="header-button-container">
                    <div className="button-header" onClick={handleChangeRoute(ROUTES.ROOT)}>
                        <img
                            src={IMAGES.LOGO}
                            alt="logo"
                            className="logo-header"
                        />
                        <p className={`text-header ${generateClassSelected(ROUTES.ROOT)}`}>Mua sắm</p>
                    </div>
                    <div className="line-header" />
                    {userData.user_id ?
                        <>
                            <div className="button-header" onClick={handleChangeRoute(ROUTES.LIKE)}>
                                <Icon
                                    name={route.pathname === ROUTES.LIKE ? 'heart' : 'heart-o'}
                                    className="icon-like-header"
                                />
                            </div>
                            <div className="line-header" />
                            <div className="button-header" onClick={handleChangeRoute(ROUTES.CART)}>
                                <p className={`text-header ${generateClassSelected(ROUTES.CART)}`}>Giỏ hàng</p>
                            </div>
                            <div className="line-header" />
                            <div className="button-header" onClick={handleChangeRoute(ROUTES.USER)}>
                                <p className={`text-header ${generateClassSelected(ROUTES.USER)}`}>{userData.user_name}</p>
                                <img
                                    alt="arrow"
                                    src={IMAGES.ARROW_WHITE}
                                    className="arrow-icon-logout"
                                    onClick={toggleShowModalLogout}
                                />
                            </div>
                        </>
                        :
                        <div className="button-header" onClick={toggleModalLogin}>
                            <p className="text-header">Đăng nhập hoặc Đăng ký tài khoản</p>
                        </div>
                    }
                </div>
            </div>
            <div className='main-content'>
                {props.children}
            </div>
            <ModalLogin
                showModal={showModalLogin}
                onClose={toggleModalLogin}
            />
            {isLogined &&
                <>
                    <ModalLogout
                        showModal={showModalLogout}
                        onClose={toggleShowModalLogout}
                    />
                    <ModalDeposit
                        showModal={showModalDeposit}
                        onClose={toggleModalDeposit}
                    />
                    <Chat />
                </>
            }
        </div>
    )
})

const mapStateToProps = (state: models.GlobalState) => ({
    userData: userDataSelector(state),
    showModalDeposit: showModalDepositSelector(state),
    showModalLogin: showModalLoginSelector(state)
})

const mapDispatchToProps = {
    toggleModalDeposit: userActionCreators.toggleModalDeposit,
    toggleModalLogin: userActionCreators.toggleModalLogin,
    getUserData: userActionCreators.getUserData,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserLayout))