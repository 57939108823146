import { Button, Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Cost from "../components/common/Cost";
import { COST_TYPE, MESSAGES, PROP, RESPONSE_CODE, ROUTES, SHIP_TYPE, USER_BALANCE_CHECKOUT_TYPE } from "../utils/constants";
import product from "../models/product";
import { getListProductApi, getListProductLikeApi } from "../apis/product";
import { showNotification } from "../utils/notification";
import Loading from "../components/common/Loading";
import { calcBalanceJP, formatDecimal, getAvatar, getSymbolBalance } from "../utils/common";
import { connect } from "react-redux";
import { userActionCreators } from "../redux/actions/user";
import models from "../models";
import { ratioBalanceSelector, userDataSelector } from "../redux/selectors/user";
import user from "../models/user";
import ModalProduct from "../components/modal/ModalProduct";
import { deleteProductCartApi, getCartInfoApi, updateProductCartApi } from "../apis/cart";
import { Checkbox } from 'antd';
import cart from "../models/cart";
import Icon from 'react-fontawesome'
import Empty from "../components/common/Empty";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { type } from "os";
import BoxCk from "../components/common/BoxCk";
import { orderApi } from "../apis/order";

interface Props extends RouteComponentProps {
    toggleModalLogin: typeof userActionCreators.toggleModalLogin;
    userData: user.User;
    getUserData: typeof userActionCreators.getUserData;
    isLike?: boolean;
    ratioBalance: string;
}

const ACTION_AMOUNT = {
    ADD: 1,
    MINUS: 2
}

const Cart = (props: Props) => {
    const { toggleModalLogin, getUserData, isLike, userData, ratioBalance } = props;

    const [isLoading, setIsLoading] = useState(true)
    const [carts, setCarts] = useState<cart.Cart[]>([])
    const [inputName, setInputName] = useState<string>(userData.user_name)
    const [inputPhoneOrEmail, setInputPhoneOrEmail] = useState<string>(userData.phone_number_or_email)
    const [inputAddress, setInputAddress] = useState<string>(userData.address)
    const [isPress, setIsPress] = useState(false)
    const [showModalCk, setShowModalCk] = useState(false)
    const [showModalConfirm, setShowModalConfirm] = useState(false)
    const [costType, setCostType] = useState<number>(COST_TYPE.VN)
    const [shipType, setShipType] = useState<number>(COST_TYPE.VN)
    const [userBalanceCheckoutType, setUserBalanceCheckoutType] = useState(USER_BALANCE_CHECKOUT_TYPE.NONE)

    const getListProduct = async (pageId = 1) => {
        try {
            const response = await getCartInfoApi()
            setCarts(response.data);
            setIsLoading(false)
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR);
        }
    }

    const handleSelectCart = (itemCart: cart.Cart) => async () => {
        try {
            const { is_select, amount, cart_user_id } = itemCart;
            const newSelect = is_select === '0' ? '1' : '0';
            setCarts(carts.map(item => {
                if (item.cart_user_id === cart_user_id) {
                    return {
                        ...item,
                        is_select: is_select === '0' ? '1' : '0',
                        amount
                    }
                }
                return item
            }))
            const response = await updateProductCartApi({ cart_user_id, is_select: newSelect, amount });
            if (response.code === RESPONSE_CODE.ERROR) {
                showNotification(response.message, response.code)
            }
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR);
        }
    }

    const handleChangeAmount = (itemCart: cart.Cart, action: number) => async () => {
        try {
            const { is_select, amount, cart_user_id } = itemCart;
            let newAmount = action === ACTION_AMOUNT.ADD ? +amount + 1 : +amount - 1;
            newAmount = newAmount > 0 ? newAmount : 1;
            setCarts(carts.map(item => {
                if (item.cart_user_id === cart_user_id) {
                    return {
                        ...item,
                        is_select,
                        amount: `${newAmount}`
                    }
                }
                return item
            }))
            const response = await updateProductCartApi({ cart_user_id, is_select, amount: `${newAmount}` });
            if (response.code === RESPONSE_CODE.ERROR) {
                showNotification(response.message, response.code)
            }
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR);
        }
    }

    const handleDeleteCart = (itemCart: cart.Cart) => async () => {
        try {
            const { cart_user_id } = itemCart;
            setCarts(carts.filter(item => item.cart_user_id !== cart_user_id))
            const response = await deleteProductCartApi({ cart_user_id });
            if (response.code === RESPONSE_CODE.ERROR) {
                showNotification(response.message, response.code)
            }
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR);
        }
    }

    const handleNavigateHome = () => {
        props.history.push(ROUTES.ROOT)
    }

    const handleSelectShipType = (type: number) => () => {
        setShipType(type)
    }

    const handleSelectBalanceCheckout = (type: number) => () => {
        setUserBalanceCheckoutType(type)
    }

    const handleSelectCostType = (type: number) => () => {
        setCostType(type)
    }

    const isSelectShipVN = shipType === SHIP_TYPE.SHIP_VN;

    const calcCostBeforeShip = () => {
        let totalBalance = 0;
        carts.forEach(i => {
            if (i.is_select === '1'){
                if(isSelectShipVN) totalBalance += +i.amount * +i.balance
                else totalBalance += +i.amount * +i.balance_jp
            }
        })
        //checkship
        if (shipType === SHIP_TYPE.SHIP_JP_TYPE_1) { //370y
            totalBalance += 370;
        }
        else if (shipType === SHIP_TYPE.SHIP_JP_TYPE_2) { //520y
            totalBalance += 520;
        }
        //
        return totalBalance;
    }

    const calcCostTotal = () => {
        let totalBalance = calcCostBeforeShip();
        if(userBalanceCheckoutType === USER_BALANCE_CHECKOUT_TYPE.VN){
            totalBalance = isSelectShipVN ? totalBalance - (+userData.balance_vn) : Math.round(totalBalance - (+userData.balance_vn / +ratioBalance));
        }
        else if(userBalanceCheckoutType === USER_BALANCE_CHECKOUT_TYPE.JP) {
            totalBalance = isSelectShipVN ? Math.round(totalBalance - (+userData.balance_jp * +ratioBalance)) : totalBalance - (+userData.balance_jp);
        }
        return totalBalance > 0 ? totalBalance : 0
    }

    const handleCheckBuy = () => {
        if (calcCostTotal() > 0) {
            setShowModalCk(true)
        }
        else setShowModalConfirm(true)
    }

    const toggleModalCk = () => setShowModalCk(!showModalCk)
    const toggleModalConfirm = () => setShowModalConfirm(!showModalConfirm)

    const handleOrder = async () => {
        try {
            if (!isPress) {
                const listProductSelect = carts.filter(i => i.is_select === '1').map(i => ({
                    amount: i.amount,
                    cart_user_id: i.cart_user_id,
                    product_id: i.product_id
                })) as cart.Cart[];
                if (
                    listProductSelect.length > 0 &&
                    inputAddress &&
                    inputName &&
                    inputAddress
                ) {
                    const response = await orderApi({
                        list_product: listProductSelect,
                        ship_type: shipType,
                        cost_type: costType,
                        order_address: inputAddress,
                        order_name: inputName,
                        order_phone_or_email: inputPhoneOrEmail,
                        user_balance_checkout_type: userBalanceCheckoutType
                    })
                    showNotification(response.message, response.code);
                    if (response.code === RESPONSE_CODE.SUCCESS) {
                        setCarts(carts.filter(i => {
                            return listProductSelect.findIndex(e => e.product_id === i.product_id) < 0
                        }))
                        setShowModalConfirm(false);
                        setShowModalCk(false)
                    }
                }
                else {
                    if (listProductSelect.length === 0) showNotification('Bạn chưa chọn sản phẩm', RESPONSE_CODE.ERROR)
                    else showNotification("Thông tin vận chuyển còn thiếu", RESPONSE_CODE.ERROR)
                }
            }
        } catch (error) {
            setIsPress(false)
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR);
        }
    }

    const getBalanceCk = () =>{
        if(isSelectShipVN){
            if(costType === COST_TYPE.VN) return calcCostTotal();
            else return calcBalanceJP(calcCostTotal(), ratioBalance)
        }
        else {
            if(costType === COST_TYPE.VN) return calcCostTotal() * +ratioBalance;
            else return calcCostTotal();
        }
    }

    useEffect(() => {
        getListProduct()
    }, [isLike])

    if (isLoading) return <Loading />

    return (
        <>
            {carts.length === 0 ?
                <>
                    <Empty title='Giỏ hàng đang trống' />
                    <div className="item-center">
                        <Button className="mgt-3" onClick={handleNavigateHome}>Mua hàng ngay</Button>
                    </div>
                </>
                :
                <>
                    <div className="list-product-container row">
                        {carts.map((item) =>
                            <div className="col-md-3 col-sm-6 col-xs-12 list-product-container" key={item.cart_user_id}>
                                <div className="cart-product-item-container">
                                    <div className="relative">
                                        <img
                                            alt="product-img-select"
                                            src={getAvatar(item.image)}
                                            className="cart-product-img-select"
                                        />
                                        <div className="price-product-image-container">
                                            <Cost type={COST_TYPE.VN} price={item.balance} />
                                            <Cost type={COST_TYPE.JP} price={item.balance_jp} />
                                        </div>
                                    </div>
                                    <div className="cart-product-detail-container">
                                        <div className="cart-checkbox" onClick={handleSelectCart(item)}>
                                            <Checkbox checked={item.is_select === '1'} />
                                            <p className="bold mgt-2">Chọn</p>
                                        </div>
                                        <div className="amount-cart-container">
                                            <div className="action-amount-cart" onClick={handleChangeAmount(item, ACTION_AMOUNT.MINUS)}>
                                                <span>-</span>
                                            </div>
                                            <div className="counter-amount-cart">
                                                <span>{item.amount}</span>
                                            </div>
                                            <div className="action-amount-cart" onClick={handleChangeAmount(item, ACTION_AMOUNT.ADD)}>
                                                <span>+</span>
                                            </div>
                                        </div>
                                        <div className="cart-checkbox cancel" onClick={handleDeleteCart(item)}>
                                            <Icon name="times" className="icon-x-cart" />
                                            <p className="bold mgt">Hủy</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="row list-product-container">
                        <div className="col-md-3 col-sm-6 col-xs-12 flex align-center">
                            <div className="box-cart">
                                <p className="bold text-center text-large">Thông tin vận chuyển</p>
                                <div className="row align-center mgt-3">
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <p>Họ và tên:</p>
                                    </div>
                                    <div className="col-md-8 col-sm-12 col-xs-12">
                                        <input
                                            className="form-control"
                                            value={inputName}
                                            onChange={({ target: { value } }) => setInputName(value)}
                                        />
                                    </div>
                                </div>
                                <div className="row align-center mgt">
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <p>Số điện thoại/hòm thư:</p>
                                    </div>
                                    <div className="col-md-8 col-sm-12 col-xs-12">
                                        <input
                                            className="form-control"
                                            value={inputPhoneOrEmail}
                                            onChange={({ target: { value } }) => setInputPhoneOrEmail(value)}
                                        />
                                    </div>
                                </div>
                                <div className="row align-center mgt">
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <p>Địa chỉ:</p>
                                    </div>
                                    <div className="col-md-8 col-sm-12 col-xs-12">
                                        <input
                                            className="form-control"
                                            value={inputAddress}
                                            onChange={({ target: { value } }) => setInputAddress(value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <Icon name="arrow-right" className="arrow-checkout" />
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12 flex align-center">
                            <div className="box-cart">
                                <p className="bold text-center text-large">Bạn hãy chọn gói ship</p>
                                <div className="align-center mgt-3">
                                    <div className="flex align-center mgt pointer" onClick={handleSelectShipType(SHIP_TYPE.SHIP_VN)}>
                                        <input type="radio" checked={shipType === SHIP_TYPE.SHIP_VN} className="mgr-2" />
                                        <Cost
                                            type={COST_TYPE.VN}
                                            className="pointer"
                                        />
                                        <p className="mgl">Ship thường VN (Khách trả tiền ship)</p>
                                    </div>
                                    <div className="flex align-center mgt pointer">
                                        <div className="flex align-center" onClick={handleSelectShipType(SHIP_TYPE.SHIP_JP_NORMAL)}>
                                            <input type="radio" checked={!isSelectShipVN} className="mgr-2" />
                                            <Cost
                                                type={COST_TYPE.JP}
                                                className="pointer"
                                            />
                                        </div>
                                        <div className="mgl">
                                            <div className="flex align-center" onClick={handleSelectShipType(SHIP_TYPE.SHIP_JP_NORMAL)}>
                                                <input type="radio" checked={SHIP_TYPE.SHIP_JP_NORMAL === shipType} className="mgr-2" />
                                                <p>Ship thường JP (Khách trả tiền Ship)</p>
                                            </div>
                                            <div className="flex align-center" onClick={handleSelectShipType(SHIP_TYPE.SHIP_JP_TYPE_1)}>
                                                <input type="radio" checked={SHIP_TYPE.SHIP_JP_TYPE_1 === shipType} className="mgr-2" />
                                                <p>Bì 370y</p>
                                            </div>
                                            <div className="flex align-center" onClick={handleSelectShipType(SHIP_TYPE.SHIP_JP_TYPE_2)}>
                                                <input type="radio" checked={SHIP_TYPE.SHIP_JP_TYPE_2 === shipType} className="mgr-2" />
                                                <p>Bì 520y</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Icon name="arrow-right" className="arrow-checkout" />
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12 flex align-center">
                            <div className="box-cart">
                                <p className="bold text-center text-large">Trừ tiền trong ví</p>
                                <div className="align-center mgt-3">
                                    <div className="flex align-center mgt" onClick={handleSelectBalanceCheckout(USER_BALANCE_CHECKOUT_TYPE.NONE)}>
                                        <input type="radio" checked={userBalanceCheckoutType === USER_BALANCE_CHECKOUT_TYPE.NONE} className="mgr-2" />
                                        <p>Không</p>
                                    </div>
                                    <div className="flex align-center mgt" onClick={handleSelectBalanceCheckout(USER_BALANCE_CHECKOUT_TYPE.VN)}>
                                        <input type="radio" checked={userBalanceCheckoutType === USER_BALANCE_CHECKOUT_TYPE.VN} className="mgr-2" />
                                        <Cost
                                            type={COST_TYPE.VN}
                                            className="pointer"
                                            price={userData.balance_vn}
                                        />
                                    </div>
                                    <div className="flex align-center mgt" onClick={handleSelectBalanceCheckout(USER_BALANCE_CHECKOUT_TYPE.JP)}>
                                        <input type="radio" checked={userBalanceCheckoutType === USER_BALANCE_CHECKOUT_TYPE.JP} className="mgr-2" />
                                        <Cost
                                            type={COST_TYPE.JP}
                                            className="pointer"
                                            price={userData.balance_jp}
                                        />
                                    </div>
                                </div>
                            </div>
                            <Icon name="arrow-right" className="arrow-checkout" />
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12 flex align-center">
                            <div className="box-cart">
                                <p className="bold text-center text-large">Tổng thanh toán</p>
                                <p>Tổng số tiền giỏ hàng + ship: {formatDecimal(calcCostBeforeShip())} {getSymbolBalance(isSelectShipVN ? COST_TYPE.VN : COST_TYPE.JP)}</p>
                                <p>Số tiền cần chuyển khoản sau khi trừ: {formatDecimal(calcCostTotal()) || 0} {getSymbolBalance(isSelectShipVN ? COST_TYPE.VN : COST_TYPE.JP)}</p>
                                <div className="align-center mgt-3">
                                    {calcCostTotal() > 0 &&
                                        <>
                                            <p className="bold">Thanh toán bằng:</p>
                                            <div className="flex align-center mgt" onClick={handleSelectCostType(COST_TYPE.VN)}>
                                                <input type="radio" checked={costType === COST_TYPE.VN} className="mgr-2" />
                                                <Cost
                                                    type={COST_TYPE.VN}
                                                    className="pointer"
                                                    price={isSelectShipVN ? calcCostTotal() : calcCostTotal() * +ratioBalance}
                                                />
                                            </div>
                                            <div className="flex align-center mgt" onClick={handleSelectCostType(COST_TYPE.JP)}>
                                                <input type="radio" checked={costType === COST_TYPE.JP} className="mgr-2" />
                                                <Cost
                                                    type={COST_TYPE.JP}
                                                    className="pointer"
                                                    price={isSelectShipVN ? calcBalanceJP(calcCostTotal(), ratioBalance) : calcCostTotal()}
                                                />
                                            </div>
                                        </>
                                    }
                                </div>
                                {carts.some(i => i.is_select === '1') &&
                                    <div className="item-center mgt-3">
                                        <Button onClick={handleCheckBuy}>Mua hàng</Button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </>
            }
            <Modal
                {...PROP.MODAL}
                open={showModalCk}
                onCancel={toggleModalCk}
                width='80vh'
            >
                <div className="box item-center">
                    <p className="text-large text-center mgt-2 mgb-2">Số tiền {formatDecimal(getBalanceCk())} {getSymbolBalance(costType)}</p>
                </div>
                <BoxCk typeBank={costType === COST_TYPE.VN ? 0 : 1} />
                <div className="item-center mgt-3">
                    <Button
                        className="outline"
                        onClick={handleOrder}
                        loading={isPress}
                    >
                        Xác nhận đã chuyển khoản
                    </Button>
                </div>
            </Modal>
            <Modal
                {...PROP.MODAL}
                open={showModalConfirm}
                onCancel={toggleModalConfirm}
            >
                <p className="text-center text-large bold">Xác nhận mua hàng</p>
                <div className="item-center mgt-3">
                    <Button
                        className="outline"
                        onClick={handleOrder}
                        loading={isPress}
                    >
                        Xác nhận
                    </Button>
                </div>
            </Modal>
        </>
    )
}

const mapStateToProps = (state: models.GlobalState) => ({
    userData: userDataSelector(state),
    ratioBalance: ratioBalanceSelector(state)
})

const mapDispatchToProps = {
    toggleModalLogin: userActionCreators.toggleModalLogin,
    getUserData: userActionCreators.getUserData
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Cart));