import axios from 'axios';
import { END_POINTS } from '..';
import models from '../../models';
import order from '../../models/order';
const qs = require('qs');

export const orderBalanceApi  = (params: order.OrderBalanceRequest) =>{
    const url = END_POINTS.orderBalance;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}

export const orderCkApi = (params: order.OrderBalanceRequest) =>{
    const url = END_POINTS.orderCk;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}

export const orderApi = (params: order.OrderRequest) =>{
    const url = END_POINTS.order;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}