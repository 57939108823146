import React, { useEffect, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import models from '../../models';
import { ratioBalanceSelector, userDataSelector } from '../../redux/selectors/user';
import user from '../../models/user';
import Icon from 'react-fontawesome'
import { dataURLtoFile, formatDecimal, getAvatar, getImage64, getSymbolBalance, replaceCost, uuidv4 } from '../../utils/common';
import { IMAGES } from '../../assets';
import TextareaAutosize from 'react-textarea-autosize';
import { COST_TYPE, MESSAGES, RESPONSE_CODE } from '../../utils/constants';
import { Button } from 'antd';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import { showNotification } from '../../utils/notification';
import { updateProductApi } from '../../apis/admin/product';
import product from '../../models/product';

type Props = {
    userData: user.User;
    productSelect?: product.Product;
    onUpdateSuccess?: () => void;
    ratioBalance: string;
}

const AddProduct = React.memo((props: Props) => {
    const { productSelect, onUpdateSuccess, ratioBalance } = props;

    const [primaryImage, setPrimaryImage] = useState('')
    const [images, setImages] = useState<string[]>([])
    const [name, setName] = useState('')
    const [info, setInfo] = useState('')
    const [balanceVN, setBalanceVN] = useState('')
    const [balanceJP, setBalanceJP] = useState('')
    const [dateExpire, setDateExpire] = useState<Date | null>(moment().add(1, 'months').toDate())
    const [isPress, setIsPress] = useState(false)

    const imagePrimaryRef = useRef<HTMLInputElement>(null)
    const imageSubRef = useRef<HTMLInputElement>(null)
    const imageTimeoutRef = useRef<NodeJS.Timeout>()
    const imagesRef = useRef<string[]>([])

    const isEdit = Boolean(productSelect?.product_id)

    const handleChangePrimaryImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event?.target?.files?.[0] as File;
        getImage64(file, image64 => {
            setPrimaryImage(image64)
        });
        if (imagePrimaryRef.current) {
            imagePrimaryRef.current.value = ''
        }
    }

    const handleClickPrimaryImage = () => {
        imagePrimaryRef.current?.click()
    }

    const handleClickSubImage = () => {
        imageSubRef.current?.click()
    }

    const handleChangeSubImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        imagesRef.current = [];
        const files = event?.target?.files;
        if (files && files?.length > 0) {
            for (var i = 0; i < files.length; i++) {
                getImage64(files[i], image64 => {
                    imagesRef.current.push(image64)
                    if (imageTimeoutRef.current) clearTimeout(imageTimeoutRef.current)
                    setTimeout(() => {
                        setImages([...images, ...imagesRef.current])
                    }, 700)
                });
            }
            setTimeout(() => {
                setImages([...images, ...imagesRef.current])
            }, 1200)
            if (imageSubRef.current) {
                imageSubRef.current.value = ''
            }
        }
    }

    const deleteSubImage = (index: number) => () => {
        setImages(images.filter((_, k) => k !== index))
    }

    const setData = (data: product.Product = {} as product.Product) =>{
        setPrimaryImage(data.image || '')
        setImages(data.images ? data.images.map(i => i.asset_data) : [])
        setName(data.name || '')
        setInfo(data.info || '')
        setBalanceVN(formatDecimal(data.balance))
        setBalanceJP(formatDecimal(data.balance_jp))
        setDateExpire(data.expire_date ? moment(data.expire_date, "YYYY-MM-DD").toDate() : moment().add(1, 'months').toDate())
    }

    const handleAddProduct = async() =>{
        try {
            if (!isPress) {
                const balanceVNConvert = replaceCost(balanceVN);
                const balanceJPConvert = replaceCost(balanceJP);
                let dateExpireConvert: string | null = moment(dateExpire).format("YYYY-MM-DD HH:mm");
                dateExpireConvert = dateExpireConvert === 'Invalid date' ? '' : dateExpireConvert;
                if(primaryImage && name && info && balanceVNConvert && balanceJPConvert && dateExpire){
                    const files = images.filter(i => i.includes('data:image')).map((item) => dataURLtoFile(item, `${uuidv4()}.png`))
                    const formData = new FormData();
                    if(isEdit){
                        formData.append('product_id', productSelect?.product_id!)
                        const imagesOld = images.filter(i => !i.includes('data:image'));
                        imagesOld.forEach((item, index)=>{
                            formData.append(`images_old[${index}]`, item);
                        });
                    }
                    formData.append('name', name)
                    formData.append('info', info);
                    formData.append('image', primaryImage);
                    formData.append('balance', `${balanceVNConvert}`);
                    formData.append('balance_jp', `${balanceJPConvert}`);
                    formData.append('expire_date', dateExpireConvert);
                    files.forEach((file, index)=>{
                        formData.append(`images[${index}]`, file);
                    });
                    setIsPress(true)
                    const response = await updateProductApi(formData);
                    setIsPress(false)
                    showNotification(response.message, response.code)
                    if (response.code === RESPONSE_CODE.SUCCESS) {
                        if(isEdit){
                            onUpdateSuccess?.();
                        }
                        else setData()
                    }
                }
                else if(!primaryImage) showNotification('Ảnh chính không được để trống', RESPONSE_CODE.ERROR);
                else if(!name) showNotification('Tên hàng không được để trống', RESPONSE_CODE.ERROR);
                else if(!info) showNotification('Mô tả sản phẩm không được để trống', RESPONSE_CODE.ERROR);
                else if(!balanceVNConvert) showNotification('Số tiền VN không được để trống', RESPONSE_CODE.ERROR);
                else if(!balanceJPConvert) showNotification('Số tiền JP không được để trống', RESPONSE_CODE.ERROR);
                else if(!dateExpire) showNotification('Thời gian hết hàng không được để trống', RESPONSE_CODE.ERROR);
            }
        } catch (error) {
            setIsPress(false)
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR);
        }
    }

    const changeBalanceVN = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>{
        setBalanceVN(formatDecimal(value))
    }

    const changeBalanceJP = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>{
        setBalanceJP(formatDecimal(value))
    }

    useEffect(() =>{
        if(productSelect?.product_id){
            setData(productSelect)
        }
    }, [productSelect])

    return (
        <div className='row item-center'>
            <div className={isEdit ? 'col-md-12 col-sm-12 col-xs-12' : 'col-md-8 col-sm-12 col-xs-12'}>
                <div className='flex item-center'>
                    <div
                        className='upload-primary-image-product-container'
                        onClick={handleClickPrimaryImage}
                    >
                        {primaryImage ?
                            <img
                                alt='primary-img-select'
                                src={getAvatar(primaryImage)}
                                className='primary-img-select'
                            />
                            :
                            <>
                                <Icon
                                    name="plus-circle"
                                    className="add-primary-image-icon"
                                />
                                <p className='mgt-3'>Thêm ảnh chính</p>
                            </>
                        }
                    </div>
                </div>
                <div className='sub-image-upload-container'>
                    {images.map((item, key) =>
                        <div className='relative' key={key}>
                            <img
                                alt='sub-img'
                                className='sub-img-select'
                                src={getAvatar(item)}
                            />
                            <img
                                alt='delete-img'
                                className='delete-sub-img-select'
                                src={IMAGES.DELETE}
                                onClick={deleteSubImage(key)}
                            />
                        </div>
                    )}
                    <div className='flex align-center' onClick={handleClickSubImage}>
                        <div className='button-add-sub-image'>
                            <Icon
                                name="plus-circle"
                                className='add-sub-image-icon'
                            />
                        </div>
                        <p className='mgl-2'>Thêm ảnh phụ & video</p>
                    </div>
                </div>
                <div className="row align-center mgt-2">
                    <div className="col-md-2 col-sm-12 col-xs-12">
                        <p className='bold text-left text-large'>Tên hàng:</p>
                    </div>
                    <div className="col-md-10 col-sm-12 col-xs-12">
                        <input
                            className="form-control"
                            value={name}
                            onChange={({ target: { value } }) => setName(value)}
                        />
                    </div>
                </div>
                <div className="row align-center mgt-2">
                    <div className="col-md-2 col-sm-12 col-xs-12">
                        <p className='bold text-left text-large'>Mô tả sản phẩm:</p>
                    </div>
                    <div className="col-md-10 col-sm-12 col-xs-12">
                        <TextareaAutosize
                            className="textarea-form-control"
                            value={info}
                            onChange={({ target: { value } }) => setInfo(value)}
                        />
                    </div>
                </div>
                <div className="row align-center mgt-2">
                    <div className="col-md-2 col-sm-12 col-xs-12 flex flex-start">
                        <img
                            className="flag-img"
                            alt="flag"
                            src={IMAGES.VN_FLAG}
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <input
                            className="form-control"
                            value={balanceVN}
                            onChange={changeBalanceVN}
                        />
                    </div>
                    <div className="col-md-1 col-sm-12 col-xs-12">
                        <p className='text-large bold'>{getSymbolBalance(COST_TYPE.VN)}</p>
                    </div>
                </div>
                <div className="row align-center mgt-2">
                    <div className="col-md-2 col-sm-12 col-xs-12 flex flex-start">
                        <img
                            className="flag-img"
                            alt="flag"
                            src={IMAGES.JP_FLAG}
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <input
                            className="form-control"
                            value={balanceJP}
                            onChange={changeBalanceJP}
                        />
                    </div>
                    <div className="col-md-1 col-sm-12 col-xs-12">
                        <p className='text-large bold'>{getSymbolBalance(COST_TYPE.JP)}</p>
                    </div>
                </div>
                <div className="row align-center mgt-2">
                    <div className="col-md-2 col-sm-12 col-xs-12">
                        <p className='bold text-left text-large'>Thời gian hết hàng:</p>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <ReactDatePicker
                            selected={dateExpire}
                            onChange={setDateExpire}
                            dateFormat="d-M-yyyy"
                            minDate={new Date()}
                        />
                    </div>
                </div>
                <div className='item-center mgt-3 mgb-3'>
                    <Button className='btn-width-14' onClick={handleAddProduct} loading={isPress}>{isEdit ? 'Sửa' : 'Thêm mới'}</Button>
                </div>
            </div>
            <input
                type='file'
                accept="image/*"
                hidden={true}
                ref={imagePrimaryRef}
                onChange={handleChangePrimaryImage}
            />
            <input
                type="file"
                multiple
                accept="image/*"
                ref={imageSubRef}
                hidden={true}
                onChange={handleChangeSubImage}
            />
        </div>
    )
})

const mapStateToProps = (state: models.GlobalState) => ({
    userData: userDataSelector(state),
    ratioBalance: ratioBalanceSelector(state)
})

export default connect(mapStateToProps)(AddProduct);

