import axios from 'axios';
import { END_POINTS } from '..';
import models from '../../models';
import deposit from '../../models/deposit';
const qs = require('qs');

export const depositApi  = (params: deposit.DepositRequest) =>{
    const url = END_POINTS.deposit;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}

export const withdrawApi  = (params: deposit.WithdrawRequest) =>{
    const url = END_POINTS.withdraw;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}