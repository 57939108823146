import { Button, Modal } from "antd";
import { FACEBOOK_URL, MESSAGES, PROP, RESPONSE_CODE } from "../../utils/constants";
import { useState } from "react";
import { showNotification } from "../../utils/notification";
import { loginApi } from "../../apis/login";
import { applyToken } from "../../utils/common";
import { connect } from "react-redux";
import { userActionCreators } from "../../redux/actions/user";

type Props = {
    showModal: boolean;
    onClose: () => void;
    getUserDataSuccess: typeof userActionCreators.getUserDataSuccess;
}

const ModalLogin = (props: Props) => {
    const { showModal, onClose, getUserDataSuccess } = props;
    const [id, setId] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [isPress, setIsPress] = useState<boolean>(false)

    const handleChangeId = (e: React.ChangeEvent<HTMLInputElement>) => {
        setId(e.target.value)
    }

    const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value)
    }

    const handleLogin = async () => {
        try {
            if (!isPress) {
                setIsPress(true);
                if(id && password){
                    const response = await loginApi({user_id: id, user_pass: password});
                    setIsPress(false)
                    if(response.code === RESPONSE_CODE.SUCCESS){
                        applyToken(response.token)
                        showNotification('Đăng nhập thành công', RESPONSE_CODE.SUCCESS)
                        getUserDataSuccess(response.data)
                        onClose();
                    }
                    else {
                        showNotification(response.message, RESPONSE_CODE.ERROR);
                    }
                }
                else showNotification('ID và mật khẩu không được để trống', RESPONSE_CODE.ERROR);
            }
        } catch (error) {
            setIsPress(false)
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR);
        }
    }

    const handleEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.keyCode === 13) {
            handleLogin()
        }
    }

    const handleClickRegister = () =>{
        window.open(FACEBOOK_URL, '_blank')
    }

    return (
        <Modal
            {...PROP.MODAL}
            open={showModal}
            onCancel={onClose}
            zIndex={99999999}
        >
            <input
                className="form-control mgt-2"
                placeholder="ID"
                value={id}
                onChange={handleChangeId}
                onKeyDown={handleEnter}
                autoFocus
            />
            <input
                className='form-control mgt-2'
                placeholder="Mật khẩu"
                value={password}
                onChange={handleChangePassword}
                type='password'
                onKeyDown={handleEnter}
            />
            <div className="item-center mgt-3">
                <Button className="outline" onClick={handleLogin} loading={isPress}>Đăng nhập</Button>
            </div>
            <div className="hr-login" />
            <p className="text-center">
                Nhắn tin cho Ngọc để Ngọc tạo cho bạn tài khoản khách hàng thân thiết nhé! 👉 
                <span className="pointer" onClick={handleClickRegister}><u>nhắn ngay</u></span>
            </p>
        </Modal>
    )
}

const mapDispatchToProps = {
    getUserDataSuccess: userActionCreators.getUserDataSuccess
}

export default connect(undefined, mapDispatchToProps)(ModalLogin)