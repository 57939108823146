import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch, Redirect, Router } from 'react-router-dom';
import Home from '../../screens/Home';
import { ROUTES } from '../../utils/constants';
import UserLayout from '../layout/user';
import User from '../../screens/User';
import { connect } from 'react-redux';
import models from '../../models';
import { userDataSelector } from '../../redux/selectors/user';
import user from '../../models/user';
import { userActionCreators } from '../../redux/actions/user';
import Cart from '../../screens/Cart';

type Props = {
    userData: user.User;
    getUserData: typeof userActionCreators.getUserData;
    getBankData: typeof userActionCreators.getBankData;
    getListConfig: typeof userActionCreators.getListConfig;
}

const UserRoute = React.memo((props: Props) => {
    const { userData, getUserData, getBankData, getListConfig } = props;
    let { path } = useRouteMatch();

    useEffect(() =>{
        if(userData.user_id){
            getUserData()
            getBankData();
        }
    }, [userData.user_id])

    useEffect(() =>{
        getListConfig();
    }, [])

    return (
        <UserLayout>
            <Route path={ROUTES.ROOT}>
                <Switch>
                    <Route exact path={`${path}`}>
                        <Home />
                    </Route>
                    <Route path={ROUTES.LIKE}>
                        <Home isLike={true} />
                    </Route>
                    <Route path={ROUTES.CART}>
                        <Cart />
                    </Route>
                    {userData.user_id &&
                        <Route path={ROUTES.USER}>
                            <User />
                        </Route>
                    }
                    <Route render={() => <Redirect to={`${path}`} />} />
                </Switch>
            </Route>
        </UserLayout>
    )
})

const mapStateToProps = (state: models.GlobalState) =>({
    userData: userDataSelector(state),
})

const mapDispatchToProps = {
    getUserData: userActionCreators.getUserData,
    getBankData: userActionCreators.getBankData,
    getListConfig: userActionCreators.getListConfig
}

export default connect(mapStateToProps, mapDispatchToProps)(UserRoute);

