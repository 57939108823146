import { useEffect, useState, useRef } from "react";
import { MESSAGES, PROP, RESPONSE_CODE, TYPE_BANK } from "../../utils/constants";
import { showNotification } from "../../utils/notification";
import { Button, Modal } from "antd";
import models from "../../models";
import { connect } from "react-redux";
import { bankDataSelector, ratioBalanceSelector } from "../../redux/selectors/user";
import user from "../../models/user";
import { changeBankApi } from "../../apis/admin/user";
import { userActionCreators } from "../../redux/actions/user";
import { changeRatioBalanceAdminApi } from "../../apis/admin/config";
import Icon from 'react-fontawesome'
import { getAvatar, getImage64 } from "../../utils/common";
const ButtonGroup = Button.Group;

type Props = {
    showModal: boolean;
    onClose: () => void;
    bankData: user.Bank[];
    getBankData: typeof userActionCreators.getBankData;
    ratioBalance: string
    getListConfig: typeof userActionCreators.getListConfig;
}

const RATIO = 999;

const ModalEditBank = (props: Props) => {
    const { onClose, showModal, bankData, getBankData, ratioBalance, getListConfig } = props
    const [typeBank, setTypeBank] = useState(TYPE_BANK.VN)

    const [bankName, setBankName] = useState('')
    const [bankStk, setBankStk] = useState('')
    const [bankUserName, setBankUserName] = useState('')
    const [bankBranchName, setBankBranchName] = useState('')
    const [ratioValue, setRatioValue] = useState(ratioBalance)
    const [bankImage, setBankImage] = useState('')

    const [isPress, setIsPress] = useState(false)
    const imageBankRef = useRef<HTMLInputElement>(null)

    const buttonGroupClass = (selectItem: number) => {
        if (selectItem === typeBank) return 'button-group-home button-group-selected';
        return 'button-group-home';
    }

    const handleToggleTab = (tab: number) => () => {
        setTypeBank(tab)
    }

    const bankDataType = bankData[typeBank]

    const setBankData = (data: user.Bank) => {
        setBankName(data?.bank_name)
        setBankStk(data?.bank_stk)
        setBankUserName(data?.bank_user_name)
        setBankBranchName(data?.bank_branch_name)
        setBankImage(data?.bank_image)
    }

    const handleSave = async () => {
        try {
            if (!isPress) {
                setIsPress(true);
                const response = await changeBankApi({
                    bank_id: typeBank === TYPE_BANK.VN ? '1' : '2',
                    bank_name: bankName,
                    bank_stk: bankStk,
                    bank_user_name: bankUserName,
                    bank_branch_name: bankBranchName,
                    bank_image: bankImage
                })
                setIsPress(false)
                showNotification(response.message, response.code)
                if (response.code === RESPONSE_CODE.SUCCESS) {
                    getBankData();
                }
            }
        } catch (error) {
            setIsPress(false)
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR);
        }
    }

    const handleSaveRatio = async () => {
        try {
            if (!isPress) {
                setIsPress(true);
                const response = await changeRatioBalanceAdminApi({
                    ratio: ratioValue
                })
                setIsPress(false)
                showNotification(response.message, response.code)
                if (response.code === RESPONSE_CODE.SUCCESS) {
                    getListConfig();
                }
            }
        } catch (error) {
            setIsPress(false)
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR);
        }
    }

    const handleClickImage = () => {
        imageBankRef.current?.click()
    }

    const handleChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event?.target?.files?.[0] as File;
        getImage64(file, image64 => {
            setBankImage(image64)
        });
        if (imageBankRef.current) {
            imageBankRef.current.value = ''
        }
    }

    useEffect(() => {
        if (typeBank !== RATIO) {
            setBankData(bankDataType)
        }
    }, [typeBank, bankDataType])

    return (
        <Modal
            {...PROP.MODAL}
            open={showModal}
            onCancel={onClose}
            width='80vh'
        >
            <ButtonGroup className='item-center mgt-2 wrap' style={{ display: 'flex', width: '100%' }}>
                <Button className={buttonGroupClass(TYPE_BANK.VN)} onClick={handleToggleTab(TYPE_BANK.VN)}>Bank VN</Button>
                <Button className={buttonGroupClass(TYPE_BANK.JP)} onClick={handleToggleTab(TYPE_BANK.JP)}>Bank JP</Button>
                <Button className={buttonGroupClass(RATIO)} onClick={handleToggleTab(RATIO)}>Tỉ giá</Button>
            </ButtonGroup>
            {typeBank === RATIO ?
                <div className="flex-column item-center mgt-3">
                    <div className="flex item-center flex-wrap">
                        <p className='bold mgr'>1 Yên =</p>
                        <input
                            className="form-control"
                            style={{ width: '50%' }}
                            value={ratioValue}
                            onChange={({ target: { value } }) => setRatioValue(value)}
                        />
                        <p className='bold mgl'>VNĐ</p>
                    </div>
                    <div className="item-center mgt-3">
                        <Button onClick={handleSaveRatio} loading={isPress}>Lưu</Button>
                    </div>
                </div>
                :
                <>
                    <div className="row align-center mgt-3">
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <p className='bold'>Ngân hàng:</p>
                        </div>
                        <div className="col-md-8 col-sm-12 col-xs-12">
                            <input
                                className="form-control"
                                value={bankName}
                                onChange={({ target: { value } }) => setBankName(value)}
                            />
                        </div>
                    </div>
                    <div className="row align-center mgt">
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <p className='bold'>Số tài khoản:</p>
                        </div>
                        <div className="col-md-8 col-sm-12 col-xs-12">
                            <input
                                className="form-control"
                                value={bankStk}
                                onChange={({ target: { value } }) => setBankStk(value)}
                            />
                        </div>
                    </div>
                    <div className="row align-center mgt">
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <p className='bold'>Chủ tài khoản:</p>
                        </div>
                        <div className="col-md-8 col-sm-12 col-xs-12">
                            <input
                                className="form-control"
                                value={bankUserName}
                                onChange={({ target: { value } }) => setBankUserName(value)}
                            />
                        </div>
                    </div>
                    <div className="row align-center mgt">
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <p className='bold'>Chi nhánh:</p>
                        </div>
                        <div className="col-md-8 col-sm-12 col-xs-12">
                            <input
                                className="form-control"
                                value={bankBranchName}
                                onChange={({ target: { value } }) => setBankBranchName(value)}
                            />
                        </div>
                    </div>
                    {typeBank === TYPE_BANK.JP &&
                        <div className="row align-center mgt-2">
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <p className='bold'>Hình ảnh:</p>
                            </div>
                            <div className="col-md-8 col-sm-12 col-xs-12">
                                {bankImage ?
                                    <img
                                        alt='primary-img-select'
                                        src={getAvatar(bankImage)}
                                        className='primary-img-select'
                                        onClick={handleClickImage}
                                    />
                                    :
                                    <div className='button-add-sub-image' onClick={handleClickImage}>
                                        <Icon
                                            name="plus-circle"
                                            className='add-sub-image-icon'
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    <div className="item-center mgt-3">
                        <Button onClick={handleSave} loading={isPress}>Lưu</Button>
                    </div>
                </>
            }
            <input
                type='file'
                accept="image/*"
                hidden={true}
                ref={imageBankRef}
                onChange={handleChangeImage}
            />
        </Modal>
    )
}

const mapStateToProps = (state: models.GlobalState) => ({
    bankData: bankDataSelector(state),
    ratioBalance: ratioBalanceSelector(state),
})

const mapDispatchToProps = {
    getBankData: userActionCreators.getBankData,
    getListConfig: userActionCreators.getListConfig
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditBank)