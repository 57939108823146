import { useEffect, useRef, useState } from "react";
import Cost from "../../components/common/Cost";
import { COST_TYPE, DEFAULT_PAGE_LIMIT, MESSAGES, PROP, RESPONSE_CODE, TYPE_STATUS } from "../../utils/constants";
import { showNotification } from "../../utils/notification";
import Loading from "../../components/common/Loading";
import { Button, Modal } from "antd";
import InfiniteScroll from "react-infinite-scroller";
import { convertDateTime, getAvatar } from "../../utils/common";
import { changeStatusOrderAdminApi, getListOrderAdminApi } from "../../apis/admin/order";
import order from "../../models/order";
import chat from "../../models/chat";
import { getListUserChatAdminApi } from "../../apis/admin/chat";
import moment from "moment";
import ModalChat from "../../components/modal/ModalChat";
import { userActionCreators } from "../../redux/actions/user";
import { connect } from "react-redux";
import { adminActionCreators } from "../../redux/actions/admin";
import admin from "../../models/admin";
import models from "../../models";
import { countDataSelector } from "../../redux/selectors/admin";
import { EventBusName, onPushEventBus } from "../../services/event-bus";
import TooltipUser from "../../components/common/TooltipUser";
const ButtonGroup = Button.Group;

const TYPE_CHAT = {
    NO_REPLY: 0,
    REPLY: 1
}

type Props = {
    toggleModalChat: typeof userActionCreators.toggleModalChat;
    countData: admin.Count | any
}

const ManageChat = (props: Props) => {
    const [typeChat, setTypeChat] = useState(TYPE_CHAT.NO_REPLY)
    const [isLoading, setIsLoading] = useState(true)
    const [userChats, setUserChats] = useState<chat.ListUserChat[]>([])

    const { toggleModalChat, countData } = props;

    const handleToggleTab = (tab: number) => () => {
        setTypeChat(tab)
    }

    const buttonGroupClass = (selectItem: number) => {
        if (selectItem === typeChat) return 'button-group-home button-group-selected';
        return 'button-group-home';
    }

    const getListUserChat = async () => {
        try {
            const response = await getListUserChatAdminApi({ type: typeChat })
            setUserChats(response.data);
            setIsLoading(false)
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR);
        }
    }

    const handleSelectChatUser = (data: string) => () =>{
        onPushEventBus(EventBusName.SET_CHAT_GROUP_ID_ADMIN, data)
        toggleModalChat()
    }

    useEffect(() => {
        getListUserChat()
    }, [typeChat])

    return (
        <div className="manage-deposit-container">
            <ButtonGroup className='item-center mgt wrap' style={{ display: 'flex', width: '100%' }}>
                <Button className={buttonGroupClass(TYPE_CHAT.NO_REPLY)} onClick={handleToggleTab(TYPE_CHAT.NO_REPLY)}>Chưa trả lời ({countData.totalChatNoReply})</Button>
                <Button className={buttonGroupClass(TYPE_CHAT.REPLY)} onClick={handleToggleTab(TYPE_CHAT.REPLY)}>Đã trả lời ({countData.totalChatReply})</Button>
            </ButtonGroup>
            {isLoading ? <Loading />
                :
                <>
                    {userChats.map(item =>
                        <div className="box-list-user-chat-admin row" key={item.chat_group_id} onClick={handleSelectChatUser(item.chat_group_id)}>
                            <div className="col-md-2 col-sm-12 col-xs-12 left-col-product-list-admin">
                                <TooltipUser data={item as any} className="avatar-user-chat-admin" />
                                <div className="mgl">
                                    <p className="bold color-white">{item.user_name}</p>
                                    <p className="color-white">{item.time_ago}</p>
                                </div>
                                <div className="hr-product-list-admin bg-white" />
                            </div>
                            <div className="col-md-10 col-sm-12 col-xs-12 flex align-center">
                                <div >
                                    <p className="color-white">{item.message}</p>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            }
        </div>
    )
}

const mapStateToProps = (state: models.GlobalState) => ({
    countData: countDataSelector(state),
})

const mapDispatchToProps = {
    toggleModalChat: userActionCreators.toggleModalChat,
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageChat)