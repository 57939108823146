import axios from 'axios';
import { END_POINTS } from '..';
import models from '../../models';
import user from '../../models/user';
const qs = require('qs');

export const getUserDataApi = () =>{
    const url = END_POINTS.getUserData;
	return axios.get(url).then((res: { data: user.UserResponse }) => res.data);
}

export const updateMineApi  = (params: user.UpdateUserRequest) =>{
    let url = END_POINTS.updateMine;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}

export const changePasswordApi = (params: user.ChangePassParams) =>{
    const url = END_POINTS.changePassword;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}

export const getListLogUserApi = (params: user.ListLogUserRequest) =>{
    const url = END_POINTS.getListLogUser;
	return axios.get(url, {params}).then((res: { data: user.ListLogUserResponse }) => res.data);
}

export const getDataTooltipUserApi = ({user_id}: {user_id: string}) =>{
    const url = END_POINTS.getDataTooltipUser;
	return axios.get(url, {params: {user_id}}).then((res: { data: user.UserResponse }) => res.data);
}

export const getBankDataApi = () =>{
    const url = END_POINTS.getBankData;
	return axios.get(url).then((res: { data: user.BankResponse }) => res.data);
}