import React, { useEffect, useRef, useState } from "react";
import Cost from "../../components/common/Cost";
import { COST_TYPE, DEFAULT_PAGE_LIMIT, MESSAGES, PROP, RESPONSE_CODE } from "../../utils/constants";
import { showNotification } from "../../utils/notification";
import Loading from "../../components/common/Loading";
import { confirmDepositAdminApi, getListDepositAdminApi } from "../../apis/admin/deposit";
import deposit from "../../models/deposit";
import { Button, Modal } from "antd";
import InfiniteScroll from "react-infinite-scroller";
import { convertDate, getAvatar, getSymbolBalance } from "../../utils/common";
import moment from "moment";
import { adminActionCreators } from "../../redux/actions/admin";
import admin from "../../models/admin";
import { countDataSelector } from "../../redux/selectors/admin";
import models from "../../models";
import { connect } from "react-redux";
import TooltipUser from "../../components/common/TooltipUser";
import ModalEditBank from "../../components/modal/ModalEditBank";
import { ratioBalanceSelector } from "../../redux/selectors/user";
const ButtonGroup = Button.Group;

const TYPE_DEPOSIT = {
    DAY: 1,
    CONFIRM: 2,
    OVER_DAY: 3
}

interface Props {
    getCountAdmin: typeof adminActionCreators.getCountAdmin;
    countData: admin.Count | any;
    ratioBalance: string
}

const ManageDeposit = (props: Props) => {
    const [isLoading, setIsLoading] = useState(true)
    const [deposits, setDeposits] = useState<deposit.Deposit[]>([])
    const [typeDeposit, setTypeDeposit] = useState(TYPE_DEPOSIT.DAY)
    const [endList, setEndList] = useState<boolean>(false)
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false)
    const [showModalEditBank, setShowModalEditBank] = useState<boolean>(false)
    const [depositSelected, setDepositSelected] = useState<deposit.Deposit>({} as deposit.Deposit)
    const [isPress, setIsPress] = useState(false)

    const { getCountAdmin, countData, ratioBalance } = props;

    const pageIdRef = useRef<number>(1)

    const buttonGroupClass = (selectItem: number) => {
        if (selectItem === typeDeposit) return 'button-group-home button-group-selected';
        return 'button-group-home';
    }

    const getListDeposit = async (pageId = 1) => {
        try {
            const response = await getListDepositAdminApi({ page_id: pageId, type: typeDeposit })
            setDeposits(pageId === 1 ? response.data : [...deposits, ...response.data]);
            setEndList(response?.data?.length < DEFAULT_PAGE_LIMIT)
            pageIdRef.current = pageId;
            setIsLoading(false)
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR);
        }
    }

    const handleToggleTab = (tab: number) => () => {
        setTypeDeposit(tab)
    }

    const handleLoadMore = () => {
        if (!endList) {
            getListDeposit(pageIdRef.current + 1)
        }
    }

    const toggleShowModalConfirm = () => {
        setShowModalConfirm(!showModalConfirm)
    }

    const toggleShowModalEditBank = () => {
        setShowModalEditBank(!showModalEditBank)
    }

    const handleSelectDeposit = (item: deposit.Deposit) => () => {
        setDepositSelected(item)
        setShowModalConfirm(true)
    }

    const handleConfirm = async () => {
        try {
            if (!isPress) {
                setIsPress(true)
                const response = await confirmDepositAdminApi({ deposit_id: depositSelected.deposit_id });
                setIsPress(false)
                showNotification(response.message, response.code)
                if (response.code === RESPONSE_CODE.SUCCESS) {
                    getListDeposit();
                    getCountAdmin();
                    toggleShowModalConfirm();
                }
            }
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR);
            setIsPress(false)
        }
    }

    useEffect(() => {
        getListDeposit();
    }, [typeDeposit])

    if (isLoading) return <Loading />

    return (
        <div className="manage-deposit-container">
            <div className="box flex space-between align-center flex-wrap">
                <div>
                    <Cost textClass="text-large bold" type={COST_TYPE.VN} price={countData.totalBalanceVN} />
                    <Cost textClass="text-large bold" type={COST_TYPE.JP} price={countData.totalBalanceJP} />
                    <p className="text-large mgt bold">1 {getSymbolBalance(COST_TYPE.JP)} = {ratioBalance} {getSymbolBalance(COST_TYPE.VN)}</p>
                </div>
                <Button onClick={toggleShowModalEditBank}>Sửa thông tin chuyển khoản</Button>
            </div>
            <ButtonGroup className='item-center mgt-2 wrap' style={{ display: 'flex', width: '100%' }}>
                <Button className={buttonGroupClass(TYPE_DEPOSIT.DAY)} onClick={handleToggleTab(TYPE_DEPOSIT.DAY)}>24h Chưa duyệt ({countData.totalDepositDay})</Button>
                <Button className={buttonGroupClass(TYPE_DEPOSIT.CONFIRM)} onClick={handleToggleTab(TYPE_DEPOSIT.CONFIRM)}>Đã duyệt ({countData.totalDepositConfirm})</Button>
                <Button className={buttonGroupClass(TYPE_DEPOSIT.OVER_DAY)} onClick={handleToggleTab(TYPE_DEPOSIT.OVER_DAY)}>Quá 24h ({countData.totalDepositOverDay})</Button>
            </ButtonGroup>
            <InfiniteScroll
                loadMore={handleLoadMore}
                hasMore={!endList && !isLoading}
                loader={<Loading />}
                threshold={20}
            >
                {deposits.map((item) =>
                    <div className="box-list-deposit-admin row" key={item.deposit_id}>
                        <div className="col-md-2 col-sm-12 col-xs-12 left-col-product-list-admin">
                            <div>
                                <p>{moment(item.cr_date_time).format("HH:mm")}</p>
                                <p className="bold">{convertDate(item.cr_date_time)}</p>
                            </div>
                            <div className="hr-product-list-admin" />
                        </div>
                        <div className="col-md-8 col-sm-12 col-xs-12 flex align-center">
                            <TooltipUser data={item} className="avatar-user-small" />
                            <div className="mgl-2">
                                <p className="bold">Khách hàng: {item.user_name}</p>
                                <div className="flex align-center">
                                    <p className="bold">{item.is_withdraw === '1' ? 'Rút tiền' : 'Nạp tiền'}</p>
                                    <Cost className="mgl" type={+item.balance_type} price={item.balance} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12 col-xs-12">
                            {item.is_approve === '0' &&
                                <div
                                    className="flex flex-end align-center pointer"
                                >
                                    <Button className="outline" onClick={handleSelectDeposit(item)}>Xác nhận - Duyệt</Button>
                                </div>
                            }
                        </div>
                    </div>
                )}
            </InfiniteScroll>
            <ModalEditBank
                showModal={showModalEditBank}
                onClose={toggleShowModalEditBank}
            />
            <Modal
                {...PROP.MODAL}
                open={showModalConfirm}
                onCancel={toggleShowModalConfirm}
            >
                <div className="item-center">
                    <p className="text-large text-center mgt-2 mgb-2">Duyệt nạp tiền cho <span className="bold">{depositSelected.user_name}</span> số tiền:</p>
                    <Cost className="mgl item-center" type={+depositSelected.balance_type} price={depositSelected.balance} />
                </div>
                <div className="item-center mgt-3">
                    <Button
                        className="outline"
                        onClick={handleConfirm}
                        loading={isPress}
                    >
                        Xác nhận
                    </Button>
                </div>
            </Modal>
        </div>
    )
}

const mapStateToProps = (state: models.GlobalState) => ({
    countData: countDataSelector(state),
    ratioBalance: ratioBalanceSelector(state),
})

const mapDispatchToProps = {
    getCountAdmin: adminActionCreators.getCountAdmin
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageDeposit)