import { useEffect, useRef, useState } from "react";
import { DEFAULT_PAGE_LIMIT, MESSAGES, PROP, RESPONSE_CODE } from "../../utils/constants";
import { showNotification } from "../../utils/notification";
import Loading from "../../components/common/Loading";
import { Button, Modal, Switch } from "antd";
import InfiniteScroll from "react-infinite-scroller";
import order from "../../models/order";
import { getListUserAdminApi, resetPasswordAdminApi, toggleBlockUserApi } from "../../apis/admin/user";
import user from "../../models/user";
import { getAvatar } from "../../utils/common";
import ModalAddUser from "../../components/modal/ModalAddUser";
import Icon from 'react-fontawesome'
import { adminActionCreators } from "../../redux/actions/admin";
import { connect } from "react-redux";
import TooltipUser from "../../components/common/TooltipUser";

const ManageUser = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [users, setUsers] = useState<user.User[]>([])
    const [endList, setEndList] = useState<boolean>(false)
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false)
    const [userSelected, setUserSelected] = useState<user.User>({} as user.User)
    const [showModalAddUser, setShowModalAddUser] = useState<boolean>(false)
    const [isPress, setIsPress] = useState(false)

    const pageIdRef = useRef<number>(1)

    const getListUser = async (pageId = 1) => {
        try {
            const response = await getListUserAdminApi({ page_id: pageId })
            setUsers(pageId === 1 ? response.data : [...users, ...response.data]);
            setEndList(response?.data?.length < DEFAULT_PAGE_LIMIT)
            pageIdRef.current = pageId;
            setIsLoading(false)
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR);
        }
    }

    const handleLoadMore = () => {
        if (!endList) {
            getListUser(pageIdRef.current + 1)
        }
    }

    const handleChangeIsBlock = (user: user.User) => async () => {
        try {
            const isBlock = user.is_block === '0' ? '1' : '0';
            setUsers(users.map(i => i.user_id === user.user_id ? { ...i, is_block: isBlock } : i))
            const response = await toggleBlockUserApi({
                user_id: user.user_id,
                is_block: isBlock
            })
            showNotification(response.message, response.code)
            if (response.code !== RESPONSE_CODE.SUCCESS) {
                setUsers(users.map(i => i.user_id === user.user_id ? { ...i, is_block: user.is_block } : i))
            }
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
            setUsers(users.map(i => i.user_id === user.user_id ? { ...i, is_block: user.is_block } : i))
        }
    }

    const showModalReset = (userData: user.User) => () => {
        setUserSelected(userData)
        setShowModalConfirm(true)
    }

    const toggleModalConfirm = () => {
        setShowModalConfirm(!showModalConfirm)
        setUserSelected({} as user.User)
    }

    const handleConfirmResetPass = async () => {
        try {
            setIsPress(true)
            const response = await resetPasswordAdminApi({ user_id: userSelected.user_id })
            setIsPress(false)
            showNotification(response.message, response.code)
            if (response.code === RESPONSE_CODE.SUCCESS) {
                setShowModalConfirm(false)
            }
        } catch (error) {
            setIsPress(false)
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
        }
    }

    const handleClickLink = (link: string) => () => {
        window.open(link, '_blank')
    }

    const toggleModalAddUser = () => {
        setUserSelected({} as user.User)
        setShowModalAddUser(!showModalAddUser)
    }

    const handleEdit = (userData: user.User) => () => {
        setUserSelected(userData)
        setShowModalAddUser(true)
    }

    useEffect(() => {
        getListUser();
    }, [])

    return (
        <div className="manage-deposit-container">
            <div className="flex item-center pointer mgb" onClick={toggleModalAddUser}>
                <Icon
                    name="plus-circle"
                    className="add-user-icon mgr-2"
                />
                <p><u>Thêm khách hàng mới</u></p>
            </div>
            <InfiniteScroll
                loadMore={handleLoadMore}
                hasMore={!endList && !isLoading}
                loader={<Loading />}
                threshold={20}
            >
                {users.map((user) =>
                    <div className="row row-list-user" key={user.user_id}>
                        <div className="col-md-6 col-sm-12 col-xs-12 flex align-center">
                            <TooltipUser
                                data={user}
                                className='avatar-user-admin'
                            />
                            <div className="mgl">
                                <p className="color-white bold">{user.user_name} ID: {user.user_login} </p>
                                <p className="color-white pointer" onClick={handleClickLink(user.facebook)}>
                                    <u className="color-white">{user.facebook}</u>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 col-xs-12 flex align-center justify-end">
                            <p
                                className="pointer"
                                onClick={showModalReset(user)}
                            >
                                <u className="color-white">Reset mật khẩu</u>
                            </p>
                            <p
                                className="mgl-2 mgr-2 pointer"
                                onClick={handleEdit(user)}
                            >
                                <u className="color-white">Sửa</u>
                            </p>
                            <Switch
                                checked={user.is_block === '0'}
                                onChange={handleChangeIsBlock(user)}
                            />
                        </div>
                    </div>
                )}
            </InfiniteScroll>
            <ModalAddUser
                onClose={toggleModalAddUser}
                showModal={showModalAddUser}
                onSuccess={getListUser}
                userSelected={userSelected}
            />
            <Modal
                {...PROP.MODAL}
                open={showModalConfirm}
                onCancel={toggleModalConfirm}
            >
                <div className="flex-column item-center mgt-3">
                    <p className="text-center text-large mgb-2">Xác nhận khôi phục về mật khẩu mặc định {userSelected.user_pass_default}?</p>
                    <img
                        className='avatar-user-admin'
                        key={userSelected.user_id}
                        alt='avatar-user'
                        src={getAvatar(userSelected.avatar)}
                    />
                    <p className="mgt-2">{userSelected.user_name}</p>
                    <Button
                        className="mgt-4"
                        loading={isPress}
                        onClick={handleConfirmResetPass}
                    >
                        Xác nhận
                    </Button>
                </div>
            </Modal>
        </div>
    )
}

export default ManageUser