import React, { HTMLAttributes } from "react";
import { IMAGES } from "../../assets";
import { COST_TYPE } from "../../utils/constants";
import { formatDecimal, getSymbolBalance } from "../../utils/common";

interface Props extends HTMLAttributes<HTMLDivElement> {
    type: number;
    price?: string | number;
    className?: string;
    textClass?: string;
}

const Cost = React.memo((props: Props) => {
    const { type, price, className, textClass } = props;
    return (
        <div 
            {...props}
            className={`cost-container ${(className ? className : '')}`}
        >
            {type === COST_TYPE.VN ?
                <>
                    <img
                        className="flag-img"
                        alt="flag"
                        src={IMAGES.VN_FLAG}
                    />
                    
                </>
                :
                <>
                    <img
                        className="flag-img"
                        alt="flag"
                        src={IMAGES.JP_FLAG}
                    />
                </>
            }
            {price ? <p className={`mgl ${(textClass ? textClass : '')}`}>{formatDecimal(price) || 0} {getSymbolBalance(type)}</p> : null}
        </div>
    )
})

export default Cost;