import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "antd";
import Cost from "../../components/common/Cost";
import { COST_TYPE, DEFAULT_PAGE_LIMIT, MESSAGES, PROP, RESPONSE_CODE } from "../../utils/constants";
import product from "../../models/product";
import { getListProductApi } from "../../apis/product";
import { showNotification } from "../../utils/notification";
import Loading from "../../components/common/Loading";
import { convertDate, convertDateTime, getAvatar } from "../../utils/common";
import { IMAGES } from "../../assets";
import AddProduct from "./AddProduct";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import { ratioBalanceSelector } from "../../redux/selectors/user";
import models from "../../models";

type Props = {
    ratioBalance: string;
}


const ManageProduct = (props: Props) => {
    const [isLoading, setIsLoading] = useState(true)
    const [products, setProducts] = useState<product.Product[]>([])
    const [productSelect, setProductSelect] = useState<product.Product>({} as product.Product)
    const [showModalEdit, setShowModalEdit] = useState(false)
    const [endList, setEndList] = useState<boolean>(false)

    const { ratioBalance } = props

    const pageIdRef = useRef<number>(1)

    const getListProduct = async (pageId = 1) => {
        try {
            const response = await getListProductApi({ page_id: pageId })
            setProducts(pageId === 1 ? response.data : [...products, ...response.data]);
            pageIdRef.current = pageId;
            setEndList(response?.data?.length < DEFAULT_PAGE_LIMIT)
            setIsLoading(false)
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR);
        }
    }

    const toggleModalEdit = () =>{
        setShowModalEdit(!showModalEdit)
    }

    const handleEditProduct = (data: product.Product) => () =>{
        setShowModalEdit(true)
        setProductSelect(data)
    }

    const handleUpdateSuccess = () =>{
        setShowModalEdit(false)
        setProductSelect({} as product.Product)
        getListProduct()
    }

    const handleLoadMore = () => {
        if (!endList) {
            getListProduct(pageIdRef.current + 1)
        }
    }


    useEffect(() => {
        getListProduct()
    }, [])

    if (isLoading) return <Loading />

    return (
        <>
            <InfiniteScroll
                loadMore={handleLoadMore}
                hasMore={!endList && !isLoading}
                loader={<Loading />}
                threshold={20}
            >
            {products.map(item =>
                <div className="box-list-product-admin row" key={item.product_id}>
                    <div className="col-md-2 col-sm-12 col-xs-12 left-col-product-list-admin">
                        <p className="bold">{convertDateTime(item.cr_date_time)} đến {convertDate(item.expire_date)}</p>
                        <div className="hr-product-list-admin" />
                    </div>
                    <div className="col-md-8 col-sm-12 col-xs-12 flex align-center">
                        <img
                            src={getAvatar(item.image)}
                            alt='img-product'
                            className="img-product-list-admin"
                        />
                        <div className="mgl-2">
                            <p className="text-large bold">{item.name}</p>
                            <Cost type={COST_TYPE.VN} price={item.balance} />
                            <Cost type={COST_TYPE.JP} price={item.balance_jp} />
                        </div>
                    </div>
                    <div className="col-md-2 col-sm-12 col-xs-12">
                        <div
                            className="flex flex-end align-center pointer"
                            onClick={handleEditProduct(item)}
                        >
                            <img
                                src={IMAGES.EDIT}
                                className="edit-user-img mgt"
                                alt="edit-user"
                            />
                            <p className="mgl mgt">Sửa</p>
                        </div>
                    </div>
                </div>
            )}
            </InfiniteScroll>
            <Modal
                {...PROP.MODAL}
                open={showModalEdit}
                onCancel={toggleModalEdit}
                width='120vh'
            >
                <AddProduct
                    productSelect={productSelect}
                    onUpdateSuccess={handleUpdateSuccess}
                />
            </Modal>
        </>
    )
}

const mapStateToProps = (state: models.GlobalState) => ({
    ratioBalance: ratioBalanceSelector(state)
})

export default connect(mapStateToProps)(ManageProduct);