import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import ScrollToTop from './components/common/ScrollToTop';
import { ROUTES } from './utils/constants';
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from './redux/store';
import RouteList from './components/route'

import "react-datepicker/dist/react-datepicker.css";

//config axios header token
import Cookies from 'js-cookie';
import { KEY } from './utils/constants';
import axios from 'axios';
import UserRoute from './components/route/UserRoute';
import ModalProductView from './components/modal/ModalProductView';
axios.defaults.headers.common['Auth'] = Cookies.get(KEY.TOKEN);
//

function App() {

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
          <Router>
            <ScrollToTop />
            <RouteList />
            <ModalProductView />
          </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
