import { IMAGES } from "../../assets"
import { FACEBOOK_URL } from "../../utils/constants"

const Messenger = () =>{
    return(
        <img
            src={IMAGES.MESSENGER}
            className="chat-fb-img pointer"
            alt="chat-fb-img"
            onClick={(e) =>{
                e.stopPropagation()
                window.open(FACEBOOK_URL, '_blank')
            }}
        />
    )
}

export default Messenger