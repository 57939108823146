import axios from 'axios';
import { END_POINTS } from '..';
import models from '../../models';
import chat from '../../models/chat';
const qs = require('qs')

export const sendChatApi = (params: chat.SendChatRequest) =>{
    const url = END_POINTS.sendChat;
	return axios.post(url, qs.stringify(params)).then((res: { data: chat.SendChatResponse }) => res.data);
}

export const getListChatApi = (params: { chat_group_id?: string }) =>{
    const url = END_POINTS.getListChat;
	return axios.get(url, {params}).then((res: { data: chat.ChatResponse }) => res.data);
}

export const sendAdviseApi = (params: {product_id: string}) =>{
    const url = END_POINTS.sendAdvise;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}