import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch, Redirect, Router } from 'react-router-dom';
import Home from '../../screens/Home';
import { ROUTES } from '../../utils/constants';
import AdminLayout from '../layout/admin';
import User from '../../screens/User';
import { connect } from 'react-redux';
import models from '../../models';
import { userDataSelector } from '../../redux/selectors/user';
import user from '../../models/user';
import AddProduct from '../../screens/admin/AddProduct';
import ManageProduct from '../../screens/admin/ManageProduct';
import ManageDeposit from '../../screens/admin/ManageDeposit';
import ManageSell from '../../screens/admin/ManageSell';
import ManageUser from '../../screens/admin/ManageUser';
import ManageChat from '../../screens/admin/ManageChat';
import { userActionCreators } from '../../redux/actions/user';

type Props = {
    userData: user.User;
    getListConfig: typeof userActionCreators.getListConfig;
}

const AdminRoute = React.memo(({getListConfig}: Props) => {
    let { path } = useRouteMatch();

    useEffect(() =>{
        getListConfig();
    }, [])

    return (
        <AdminLayout>
            <Route path={ROUTES.ROOT}>
                <Switch>
                    <Route exact path={`${path}`}>
                        <AddProduct />
                    </Route>
                    <Route path={ROUTES.MANAGE_PRODUCT}>
                        <ManageProduct />
                    </Route>
                    <Route path={ROUTES.MANAGE_DEPOSIT}>
                        <ManageDeposit />
                    </Route>
                    <Route path={ROUTES.MANAGE_SELL}>
                        <ManageSell />
                    </Route>
                    <Route path={ROUTES.MANAGE_USER}>
                        <ManageUser />
                    </Route>
                    <Route path={ROUTES.MANAGE_CHAT}>
                        <ManageChat />
                    </Route>
                    <Route path={ROUTES.USER}>
                        <User />
                    </Route>
                    <Route render={() => <Redirect to={`${path}`} />} />
                </Switch>
            </Route>
        </AdminLayout>
    )
})

const mapStateToProps = (state: models.GlobalState) =>({
    userData: userDataSelector(state),
})

const mapDispatchToProps = {
    getListConfig: userActionCreators.getListConfig
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminRoute);

