import axios from 'axios';
import { END_POINTS } from '..';
import product from '../../models/product';
import models from '../../models';
const qs = require('qs');

export const getListProductApi  = (params: product.GetListProductRequest) =>{
    let url = END_POINTS.getListProduct;
	return axios.get(url, {params}).then((res: { data: product.ProductResponse }) => res.data);
}

export const getDataProductApi  = (params: { product_id: string }) =>{
    let url = END_POINTS.getDataProduct;
	return axios.get(url, {params}).then((res: { data: product.ProductDetailResponse }) => res.data);
}

export const actionLikeProductApi = (params: { product_id: string, action: number }) =>{
    const url = END_POINTS.actionLikeProduct;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}

export const getListProductLikeApi  = (params: product.GetListProductRequest) =>{
    let url = END_POINTS.getListProductLike;
	return axios.get(url, {params}).then((res: { data: product.ProductResponse }) => res.data);
}