import { Modal } from "antd";
import { COST_TYPE, MESSAGES, PROP, RESPONSE_CODE } from "../../utils/constants";
import { connect } from "react-redux";
import product from "../../models/product";
import { array_move, getAvatar } from "../../utils/common";
import Cost from "../common/Cost";
import FsLightbox from 'fslightbox-react';
import { useEffect, useState } from "react";
import { showNotification } from "../../utils/notification";
import { Subscription } from 'rxjs';
import EventBus, { BaseEventType, EventBusName } from "../../services/event-bus";
import { getDataProductApi } from "../../apis/product";
import { ratioBalanceSelector, userDataSelector } from "../../redux/selectors/user";
import models from "../../models";
import user from "../../models/user";

type Props = {
    userData: user.User;
    ratioBalance: string;
}

const ModalProduct = (props: Props) => {
    const [showModal, setShowModal] = useState(false)
    const [productSelect, setProductSelect] = useState({} as product.Product)
    const images = productSelect.product_id ? [...productSelect.images?.map(item => getAvatar(item.asset_data)), getAvatar(productSelect.image)] : []
    const [imagesShow, setImagesShow] = useState<any[]>([])

    const [showPreviewImage, setShowPreviewImage] = useState<boolean>(false)

    const { ratioBalance } = props;

    const subScription = new Subscription();

    const getDataProduct = async(product_id: string) =>{
        try {
            const response = await getDataProductApi({product_id});
            if(response.code === RESPONSE_CODE.SUCCESS){
                setProductSelect(response.data);
            }
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
        }
    }

    const onRegisterEventBus = () => {
        const sub = EventBus.getInstance().events.subscribe((res: BaseEventType) => {
            if (res.type === EventBusName.TOGGLE_MODAL_PRODUCT_VIEW) {
                setShowModal(true)
                getDataProduct(res.payload)
            }
        });
        subScription.add(sub);
    };

    useEffect(() => {
        onRegisterEventBus();
        return () => {
            subScription?.unsubscribe?.();
        };
    }, []);


    const hidePreviewImage = () => {
        setShowPreviewImage(false)
    }

    const openPreviewImage = (key: number) => () => {
        setImagesShow(array_move(images, key, 0))
        setShowPreviewImage(true)
    }

    const toggleModal = () =>{
        setShowModal(!showModal)
    }

    return (
        <Modal
            {...PROP.MODAL}
            open={showModal}
            onCancel={toggleModal}
            width='100vh'
            zIndex={999999}
        >
            <div className="item-center">
                <img
                    alt="product-img-select"
                    src={getAvatar(productSelect.image)}
                    className="product-view-img-select"
                    onClick={openPreviewImage(images.length - 1)}
                />
            </div>
            <div className='sub-image-product-container'>
                {productSelect.images?.map((item, key) =>
                    <div className='relative' key={key}>
                        <img
                            alt='sub-img'
                            className='sub-img-select pointer'
                            src={getAvatar(item.asset_data)}
                            onClick={openPreviewImage(key)}
                        />
                    </div>
                )}
            </div>
            <div className="box-detail-product-container">
                <p className='text-info-product'>{productSelect.info}</p>
            </div>
            <div className="action-bottom-product-container">
                <div>
                    <Cost textClass="text-large" type={COST_TYPE.VN} price={productSelect.balance} />
                    <Cost textClass="text-large" type={COST_TYPE.JP} price={productSelect.balance_jp} />
                </div>
            </div>
            {showPreviewImage &&
                <FsLightbox
                    toggler={showPreviewImage}
                    sources={imagesShow}
                    openOnMount={true}
                    onClose={hidePreviewImage}
                    key={0}
                    type='image'
                />
            }
        </Modal>
    )
}
const mapStateToProps = (state: models.GlobalState) =>({
    userData: userDataSelector(state),
    ratioBalance: ratioBalanceSelector(state)
})
export default connect(mapStateToProps)(ModalProduct)