import { getAvatar } from "./common";

export const URI_PATH = process.env.NODE_ENV === 'production' ? 'https://ngocjp.com/backend' : 'http://localhost/jpshop-backend';
export const URI_SOCKET = process.env.NODE_ENV === 'production' ? 'https://zcity.vn:4567' : 'http://localhost:4567';

export const KEY = {
	TOKEN: '@token',
}

export const ROUTES = {
    ROOT: '/',
	USER: '/user',
	MANAGE_PRODUCT: '/manage-product',
	MANAGE_DEPOSIT: '/manage-deposit',
	MANAGE_CHAT: '/manage-chat',
	MANAGE_SELL: '/manage-sell',
	MANAGE_USER: '/manage-user',
	LIKE: '/like',
	CART: '/cart',
}

export const MESSAGES = {
	ERROR : 'Có lỗi xảy ra trong quá trình thực hiện'
}

export const RESPONSE_CODE = {
    SUCCESS: 1,
    ERROR: -1
}

export const PROP: any = {
	MODAL:{
		title: null,
		footer: null,
		bodyStyle: {background: 'white', borderRadius: '2vh'},
		zIndex: 9999,
		width: '70vh',
        centered: true,
	},
    TYPE_AHEAD:{
		isLoading: false,
		minLength: 3,
		labelKey: 'user_name',
		filterBy: ['user_name'],
		id: 'input-search-user',
		emptyLabel: (
			<div className='flex item-center'>
				<div className='flex-row search-user'>
					<p className='search-user-text'>Không tìm thấy dữ liệu</p>
				</div>
			</div>
		),
		searchText: (
			<div className='flex item-center'>
				<div className='flex-row search-user'>
					<p className='search-user-text'>Đang tìm kiếm</p>
				</div>
			</div>
		),
		promptText: (
			<div className='flex item-center'>
				<div className='flex-row search-user'>
					<p className='search-user-text'>Đang tải ...</p>
				</div>
			</div>
		),
		renderMenuItemChildren: (option: any) => (
			<div className='flex-row item-center'>
				<div className='flex-row search-user'>
					<img alt='avatar' className='avatar-guest-small' src={getAvatar(option.avatar)} />
					<p className='search-user-text'>{option.user_name}</p>
				</div>
			</div>
		)
	},
	TOOPTIP:{
		placement: 'topLeft',
		style: {width: '240px'},
		mouseEnterDelay: 0.05,
		mouseLeaveDelay: 0.05
	}
}

export const COST_TYPE ={
	VN: 1,
	JP: 2
}

export const SHIP_TYPE ={
	SHIP_VN: 1,
	SHIP_JP_NORMAL: 2,
	SHIP_JP_TYPE_1: 3,
	SHIP_JP_TYPE_2: 4
}

export const DEFAULT_PAGE_LIMIT = 10

export const TYPE_STATUS = {
    NO_DELIVERY: 0,
    DELIVERY: 1,
    CANCEL: 2,
	OVERTIME: 3
}

export const FACEBOOK_URL = 'https://www.facebook.com/store.chidam'

export const TYPE_BANK = {
    VN: 0,
    JP: 1
}

export const USER_BALANCE_CHECKOUT_TYPE = {
	NONE: 1,
	VN: 2,
	JP: 3
}

export const SHIP_TYPE_NAME:any = {
	1: 'Ship thường VN (Khách trả tiền ship)',
	2: 'Ship thường JP (Khách trả tiền Ship)',
	3: 'Bì 370y',
	4: 'Bì 520y'
}