import { Button } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Cost from "../components/common/Cost";
import { COST_TYPE, DEFAULT_PAGE_LIMIT, FACEBOOK_URL, MESSAGES, RESPONSE_CODE } from "../utils/constants";
import product from "../models/product";
import { getListProductApi, getListProductLikeApi } from "../apis/product";
import { showNotification } from "../utils/notification";
import Loading from "../components/common/Loading";
import { getAvatar } from "../utils/common";
import { connect } from "react-redux";
import { userActionCreators } from "../redux/actions/user";
import models from "../models";
import { ratioBalanceSelector, userDataSelector } from "../redux/selectors/user";
import user from "../models/user";
import ModalProduct from "../components/modal/ModalProduct";
import { addToCartApi } from "../apis/cart";
import InfiniteScroll from "react-infinite-scroller";
import Messenger from "../components/common/Messenger";

type Props = {
    toggleModalLogin: typeof userActionCreators.toggleModalLogin;
    userData: user.User;
    getUserData: typeof userActionCreators.getUserData;
    isLike?: boolean;
    ratioBalance: string;
}

const Home = (props: Props) =>{
    const [isLoading, setIsLoading] = useState(true)
    const [products, setProducts] = useState<product.Product[]>([])
    const [productSelect, setProductSelect] = useState<product.Product>({} as product.Product)
    const [showModalProduct, setShowModalProduct] = useState(false)
    const [isPress, setIsPress] = useState(false);
    const [endList, setEndList] = useState<boolean>(false)

    const { toggleModalLogin, userData, isLike } = props;

    const pageIdRef = useRef<number>(1)

    const getListProduct = async(pageId = 1) =>{
        try {
            const api = isLike ? getListProductLikeApi : getListProductApi;
            const response = await api({page_id: pageId, user_id: userData.user_id})
            setProducts(pageId === 1 ? response.data : [...products, ...response.data]);
            setEndList(response?.data?.length < DEFAULT_PAGE_LIMIT)
            pageIdRef.current = pageId;
            setIsLoading(false)
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR);
        }
    }

    const toggleModalProduct = () =>{
        setShowModalProduct(!showModalProduct)
    }

    const handleChooseProduct = (item = {} as product.Product) => () =>{
        setProductSelect(item)
        setShowModalProduct(true)
    }

    const handleLike = (productId: string, action: number) =>{
        setProductSelect({
            ...productSelect,
            product_like_id: action === 1 ? '1' : ''
        })
        setProducts(
            products.map(i => {
                if(i.product_id === productId){
                    return({
                        ...i,
                        product_like_id: action === 1 ? '1' : ''
                    })
                }
                return i;
            })
        )
    }

    const handleAddToCart = async(item: product.Product) =>{
        try {
            if(!userData.user_id){
                // toggleModalLogin();
                window.open(FACEBOOK_URL, '_blank')
                return;
            }
            if(!isPress){
                setIsPress(true)
                const response = await addToCartApi({product_id: item.product_id})
                setIsPress(false)
                showNotification(response.message, response.code);
                if(response.code === RESPONSE_CODE.SUCCESS){
                    setShowModalProduct(false)
                }
            }
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR);
            setIsPress(false)
            console.log(error);
            
        }
    }

    const handleLoadMore = () =>{
        if (!endList) {
            getListProduct(pageIdRef.current + 1)
        }
    }

    useEffect(() =>{
        getListProduct()
    }, [isLike])

    if(isLoading) return <Loading />

    return(
        <>
            <InfiniteScroll
                loadMore={handleLoadMore}
                hasMore={!endList && !isLoading}
                loader={<Loading />}
            >
            <div className="list-product-container row">
                {products.map((item) =>
                    <div className="col-md-3 col-sm-6 col-xs-12 list-product-container" key={item.product_id}>
                        <div className="product-item-container pointer" onClick={handleChooseProduct(item)}>
                            <img
                                alt="product-list-img"
                                src={getAvatar(item.image)}
                                className="product-list-img"
                            />
                            <p className="product-list-name">{item.name}</p>
                            <div className="product-detail-container">
                                <div>
                                    <Cost type={COST_TYPE.VN} price={item.balance} />
                                    <Cost type={COST_TYPE.JP} price={item.balance_jp} />
                                </div>
                                {userData.user_id ?
                                    <Button
                                    className="outline btn-add-cart"
                                    onClick={e =>{
                                        e.stopPropagation()
                                        handleAddToCart(item)
                                    }}
                                    loading={isPress}
                                >Thêm vào giỏ hàng</Button>
                                :
                                <Messenger />
                                }
                            </div>
                        </div>
                    </div>
                )}
            </div>
            </InfiniteScroll>
            {/* <ModalBuy
                showModal={showModalBuy}
                onClose={toggleModalBuy}
                productSelect={productSelect}
                onOrderSuccess={handleOrderSuccess}
            /> */}
            <ModalProduct
                showModal={showModalProduct}
                onClose={toggleModalProduct}
                productSelect={productSelect}
                onClickAddToCart={() => handleAddToCart(productSelect)}
                onClickLike={handleLike}
            />
        </>
    )
}

const mapStateToProps = (state: models.GlobalState) =>({
    userData: userDataSelector(state),
    ratioBalance: ratioBalanceSelector(state)
})

const mapDispatchToProps = {
    toggleModalLogin: userActionCreators.toggleModalLogin,
    getUserData: userActionCreators.getUserData
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);