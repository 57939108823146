import { createSelector } from 'reselect';
import models from '../../models';
import user from '../../models/user';
import { USER_INITIAL_STATE } from '../reducers/user';
import config from '../../models/config';

const userSelector = (state: models.GlobalState) => state.user;

export const userDataSelector = createSelector(
    userSelector,
    data => data.userData as user.User ?? USER_INITIAL_STATE.userData
)

export const showModalDepositSelector = createSelector(
    userSelector,
    data => data.showModalDeposit as boolean ?? USER_INITIAL_STATE.showModalDeposit
)

export const showModalLoginSelector = createSelector(
    userSelector,
    data => data.showModalLogin as boolean ?? USER_INITIAL_STATE.showModalLogin
)

export const showModalChatSelector = createSelector(
    userSelector,
    data => data.showModalChat as boolean ?? USER_INITIAL_STATE.showModalChat
)

export const bankDataSelector = createSelector(
    userSelector,
    data => data.bankData as user.Bank[] ?? USER_INITIAL_STATE.bankData
)

export const configSelector = createSelector(
    userSelector,
    data => data.configs as config.Config[] ?? USER_INITIAL_STATE.configs
)

export const ratioBalanceSelector = createSelector(
    configSelector,
    data => data.find(i => i.config_name === "RATIO_BALANCE")?.config_value || "0"
)