import { Tooltip } from "antd";
import React, { useState } from "react";
import { connect } from "react-redux";
import models from "../../models";
import user from "../../models/user";
import { getAvatar, isTouch } from "../../utils/common";
import { MESSAGES, RESPONSE_CODE, ROUTES } from "../../utils/constants";
import { showNotification } from "../../utils/notification";
import Loading from "./Loading";
import { getDataTooltipUserApi } from "../../apis/user";
import { IMAGES } from "../../assets";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { EventBusName, onPushEventBus } from "../../services/event-bus";
import { userActionCreators } from "../../redux/actions/user";

interface Props extends RouteComponentProps {
    className?: string;
    data: { user_id: string, avatar: string };
    userData?: user.User;
    component?: React.ReactNode;
    disabled?: boolean;
    toggleModalChat: typeof userActionCreators.toggleModalChat
}

const TooltipUser = (props: Props) => {
    const { className, data, component, disabled, history, toggleModalChat } = props;
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [dataTooltip, setDataToolTip] = useState<user.User>()

    const renderAvatar = () => (
        <img
            className={className ? className : 'avatar-guest'}
            key={data.user_id}
            alt='avatar-user'
            src={getAvatar(data.avatar)}
        />
    )

    const handleLoadData = async () => {
        try {
            if (isLoading) {
                const response = await getDataTooltipUserApi({ user_id: data.user_id })
                if (response.code === RESPONSE_CODE.SUCCESS) {
                    setDataToolTip(response.data)
                    setIsLoading(false)
                }
                else showNotification(response.message, response.code)
            }
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
        }
    }

    const handleChat = (chatGroupId: string) =>{
        if(chatGroupId){
            onPushEventBus(EventBusName.SET_CHAT_GROUP_ID_ADMIN, chatGroupId)
            toggleModalChat()
        }
        else history.push(ROUTES.MANAGE_CHAT)
    }

    const renderTooltip = () => {
        if (isLoading) return (
            <div style={{ height: '30vh' }}>
                <Loading />
            </div>
        )
        return (
            <div>
                <div className="item-center">
                    <img
                        className={className ? className : 'avatar-guest'}
                        key={data.user_id}
                        alt='avatar-user'
                        src={getAvatar(data.avatar)}
                    />
                    <p className="color-white text-large bold mgt">{dataTooltip?.user_name}</p>
                </div>
                <div className="mgt-2">
                    <p className="color-white text-center bold">{dataTooltip?.phone_number_or_email}</p>
                    <p className="color-white text-center bold">{dataTooltip?.address}</p>
                </div>
                <div className="mgt item-center flex">
                    {dataTooltip?.facebook &&
                        <img
                            src={IMAGES.FB}
                            alt="fb"
                            onClick={(e) => {
                                e.stopPropagation()
                                window.open(dataTooltip?.facebook, '_blank')
                            }}
                            className="fb-tooltip"
                        />
                    }
                    <img
                        src={IMAGES.CHAT}
                        alt="fb"
                        onClick={e =>{
                            e.stopPropagation()
                            handleChat((dataTooltip as any).chat_group_id)
                        }}
                        className="fb-tooltip mgl-2"
                    />
                </div>
            </div>
        )
    }

    if (disabled) {
        return (
            <>
                {component || renderAvatar()}
            </>
        )
    }

    return (
        <Tooltip
            overlayClassName='tooltip-user'
            onOpenChange={handleLoadData}
            placement='right'
            trigger={isTouch() ? 'click' : 'hover'}
            title={renderTooltip}
        >
            {component || renderAvatar()}
        </Tooltip>
    )
}

const mapDispatchToProps = {
    toggleModalChat: userActionCreators.toggleModalChat,
}

export default connect(undefined, mapDispatchToProps)(withRouter(TooltipUser))