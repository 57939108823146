import { IMAGES } from "../../assets"
import { connect } from "react-redux"
import user from "../../models/user"
import { userActionCreators } from "../../redux/actions/user"
import ModalChat from "../modal/ModalChat"

type Props = {
    toggleModalChat: typeof userActionCreators.toggleModalChat
}

const Chat = (props: Props) => {
    const { toggleModalChat } = props;

    return (
        <>
            <div className="chat-icon-container">
                <img
                    alt="chat-img"
                    src={IMAGES.CHAT}
                    className="chat-icon"
                    onClick={toggleModalChat}
                />
            </div>
            <ModalChat />
        </>
    )
}

const mapDispatchToProps = {
    toggleModalChat: userActionCreators.toggleModalChat,
}

export default connect(undefined, mapDispatchToProps)(Chat)