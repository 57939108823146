import { useEffect, useRef, useState } from 'react'
import { Button, Modal } from "antd";
import { MESSAGES, PROP, RESPONSE_CODE } from "../../utils/constants";
import { connect } from "react-redux";
import { userActionCreators } from "../../redux/actions/user";
import user from "../../models/user";
import { getAvatar, getImage64 } from "../../utils/common";
import { updateMineApi } from '../../apis/user';
import { showNotification } from '../../utils/notification';
import models from '../../models';
import { userDataSelector } from '../../redux/selectors/user';
import { IMAGES } from '../../assets';

type Props = {
    showModal: boolean;
    onClose: () => void;
    getUserDataSuccess: typeof userActionCreators.getUserDataSuccess;
    userData: user.User
}

const ModalEditUser = (props: Props) => {
    const { showModal, onClose, userData, getUserDataSuccess } = props;
    const [userName, setUserName] = useState('')
    const [emailOrPhoneNumber, setEmailOrPhoneNumber] = useState('')
    const [address, setAddress] = useState('')
    const [facebook, setFacebook] = useState('')
    const [id, setId] = useState('')
    const [passwordDefault, setPasswordDefault] = useState('')
    const [avatar, setAvatar] = useState('')
    const [isPress, setIsPress] = useState(false)

    const avatarRef = useRef<HTMLInputElement>(null)

    const handleUpdateUser = async () => {
        try {
            if (!isPress) {
                if (userName && emailOrPhoneNumber && address && id && passwordDefault) {
                    setIsPress(true);
                    const dataUpdate = {
                        user_name: userName,
                        phone_number_or_email: emailOrPhoneNumber,
                        user_login: id,
                        user_pass_default: passwordDefault,
                        address,
                        facebook,
                        avatar
                    }
                    const response = await updateMineApi(dataUpdate)
                    setIsPress(false)
                    showNotification(response.message, response.code)
                    if (response.code === RESPONSE_CODE.SUCCESS) {
                        getUserDataSuccess(dataUpdate as user.User)
                        onClose();
                    }
                }
                else if (!userName) showNotification('Họ và tên không được để trống', RESPONSE_CODE.ERROR);
                else if (!emailOrPhoneNumber) showNotification('Sđt hoặc Hòm thư không được để trống', RESPONSE_CODE.ERROR);
                else if (!address) showNotification('Địa chỉ không được để trống', RESPONSE_CODE.ERROR);
                else if (!id) showNotification('ID không được để trống', RESPONSE_CODE.ERROR);
                else if (!passwordDefault) showNotification('Mật khẩu mặc định không được để trống', RESPONSE_CODE.ERROR);
            }
        } catch (error) {
            setIsPress(false)
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR);
        }
    }

    const handleClickAvatar = () => {
        avatarRef.current?.click()
    }

    const handleChangeAvatar = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event?.target?.files?.[0] as File;
        getImage64(file, image64 => {
            setAvatar(image64)
        });
        if (avatarRef.current) {
            avatarRef.current.value = ''
        }
    }

    useEffect(() => {
        if (userData.user_id) {
            setUserName(userData.user_name || '');
            setEmailOrPhoneNumber(userData.phone_number_or_email || '')
            setAddress(userData.address || '')
            setFacebook(userData.facebook || '')
            setId(userData.user_login || '')
            setPasswordDefault(userData.user_pass_default || '')
            setAvatar(userData.avatar || '')
        }
    }, [userData, showModal])

    return (
        <Modal
            {...PROP.MODAL}
            open={showModal}
            onCancel={onClose}
            width='90vh'
        >
            <div className="item-center">
                <div className='relative'>
                    <img
                        alt="avatar-user"
                        src={getAvatar(avatar)}
                        className="avatar-user"
                        onClick={handleClickAvatar}
                    />
                    <img
                        alt="upload-avatar"
                        src={IMAGES.UPLOAD_AVATAR}
                        className="upload-avatar-img"
                        onClick={handleClickAvatar}
                    />
                </div>
            </div>
            <div className="row align-center mgt-2">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <p className='bold'>Họ và tên:</p>
                </div>
                <div className="col-md-8 col-sm-12 col-xs-12">
                    <input
                        className="form-control"
                        value={userName}
                        onChange={({ target: { value } }) => setUserName(value)}
                    />
                </div>
            </div>
            <div className="row align-center mgt">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <p className='bold'>Sđt hoặc Hòm thư:</p>
                </div>
                <div className="col-md-8 col-sm-12 col-xs-12">
                    <input
                        className="form-control"
                        value={emailOrPhoneNumber}
                        onChange={({ target: { value } }) => setEmailOrPhoneNumber(value)}
                    />
                </div>
            </div>
            <div className="row align-center mgt">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <p className='bold'>Địa chỉ:</p>
                </div>
                <div className="col-md-8 col-sm-12 col-xs-12">
                    <input
                        className="form-control"
                        value={address}
                        onChange={({ target: { value } }) => setAddress(value)}
                    />
                </div>
            </div>
            <div className="row align-center mgt">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <p className='bold'>Facebook:</p>
                </div>
                <div className="col-md-8 col-sm-12 col-xs-12">
                    <input
                        className="form-control"
                        value={facebook}
                        onChange={({ target: { value } }) => setFacebook(value)}
                    />
                </div>
            </div>
            <div className="row align-center mgt">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <p className='bold'>ID:</p>
                </div>
                <div className="col-md-8 col-sm-12 col-xs-12">
                    <input
                        className="form-control"
                        value={id}
                        onChange={({ target: { value } }) => setId(value)}
                    />
                </div>
            </div>
            <div className="row align-center mgt">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <p className='bold'>Mật khẩu mặc định:</p>
                </div>
                <div className="col-md-8 col-sm-12 col-xs-12">
                    <input
                        className="form-control"
                        value={passwordDefault}
                        onChange={({ target: { value } }) => setPasswordDefault(value)}
                    />
                </div>
            </div>
            <div className='item-center mgt-3'>
                <Button onClick={handleUpdateUser} loading={isPress}>Sửa</Button>
            </div>
            <input
                type='file'
                accept="image/png, image/jpeg"
                hidden={true}
                ref={avatarRef}
                onChange={handleChangeAvatar}
            />
        </Modal>
    )
}

const mapStateToProps = (state: models.GlobalState) => ({
    userData: userDataSelector(state)
})

const mapDispatchToProps = {
    getUserDataSuccess: userActionCreators.getUserDataSuccess
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditUser)