import { connect } from "react-redux";
import models from "../../models";
import { bankDataSelector } from "../../redux/selectors/user";
import user from "../../models/user";
import { TYPE_BANK } from "../../utils/constants";
import { getAvatar } from "../../utils/common";
import FsLightbox from "fslightbox-react";
import React, {useState} from 'react';

type Props = {
    typeBank: number
    bankData: user.Bank[];
}

const BoxCk = (props: Props) => {
    const { bankData, typeBank } = props;
    const [imagePreview, setImagePreview] = useState<string>('')

    const bankType = bankData[typeBank]

    const showPreviewImage = () => {
        setImagePreview(getAvatar(bankType?.bank_image))
    }

    return (
        <>
            <div className="box mgt">
                <p className="text-large text-center bold">Vui lòng chuyển khoản để hoàn tất</p>
                <p className="text-center mgt-3">Nội dung chuyển khoản ghi rõ:</p>
                <p className="text-center mgt"><i>“Họ và tên, SĐT hoặc Hòm thư”</i></p>
                <p className="mgt-3">Ngân hàng: <span className="bold">{bankType?.bank_name}</span></p>
                <p className="mgt">Số tài khoản: <span className="bold">{bankType?.bank_stk}</span></p>
                <p className="mgt">Chủ tài khoản: <span className="bold">{bankType?.bank_user_name}</span></p>
                <p className="mgt">Chi nhánh: <span className="bold">{bankType?.bank_branch_name}</span></p>
                {(typeBank === TYPE_BANK.JP && bankType?.bank_image) &&
                    <div className="item-center mgt-2 mgb">
                        <img
                            alt="product-img-select"
                            src={getAvatar(bankType?.bank_image)}
                            className="product-view-img-select"
                            onClick={showPreviewImage}
                        />
                    </div>
                }
            </div>
            {imagePreview &&
                <FsLightbox
                    toggler={imagePreview !== ''}
                    sources={[imagePreview]}
                    disableLocalStorage={true}
                    openOnMount={true}
                    onClose={() => setImagePreview('')}
                    key={0}
                    type='image'
                />
            }
        </>
    )
}

const mapStateToProps = (state: models.GlobalState) => ({
    bankData: bankDataSelector(state)
})

export default connect(mapStateToProps)(BoxCk)