import { useEffect, useRef, useState } from "react";
import Cost from "../../components/common/Cost";
import { COST_TYPE, DEFAULT_PAGE_LIMIT, MESSAGES, PROP, RESPONSE_CODE, SHIP_TYPE, SHIP_TYPE_NAME, TYPE_STATUS, USER_BALANCE_CHECKOUT_TYPE } from "../../utils/constants";
import { showNotification } from "../../utils/notification";
import Loading from "../../components/common/Loading";
import { Button, Modal } from "antd";
import InfiniteScroll from "react-infinite-scroller";
import { calcBalanceJP, convertDateTime, getAvatar, getBalanceCk } from "../../utils/common";
import { changeStatusOrderAdminApi, getListOrderAdminApi } from "../../apis/admin/order";
import order from "../../models/order";
import { EventBusName, onPushEventBus } from "../../services/event-bus";
import TooltipUser from "../../components/common/TooltipUser";
import admin from "../../models/admin";
import { adminActionCreators } from "../../redux/actions/admin";
import models from "../../models";
import { countDataSelector } from "../../redux/selectors/admin";
import { connect } from "react-redux";
import { ratioBalanceSelector } from "../../redux/selectors/user";
const ButtonGroup = Button.Group;

interface Props {
    getCountAdmin: typeof adminActionCreators.getCountAdmin
    countData: admin.Count | any;
    ratioBalance: string;
}

const ManageSell = (props: Props) => {
    const [isLoading, setIsLoading] = useState(true)
    const [orders, setOrders] = useState<order.OrderAdmin[]>([])
    const [status, setStatus] = useState(TYPE_STATUS.NO_DELIVERY)
    const [endList, setEndList] = useState<boolean>(false)
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false)
    const [orderSelected, setOrderSelected] = useState<order.OrderAdmin>({} as order.OrderAdmin)
    const [statusSelected, setStatusSelected] = useState<number>(0)
    const [isPress, setIsPress] = useState(false)

    const { getCountAdmin, countData, ratioBalance } = props;

    const pageIdRef = useRef<number>(1)

    const buttonGroupClass = (selectItem: number) => {
        if (selectItem === status) return 'button-group-home button-group-selected';
        return 'button-group-home';
    }

    const getListOrder = async (pageId = 1) => {
        try {
            const response = await getListOrderAdminApi({ page_id: pageId, status: status })
            setOrders(pageId === 1 ? response.data : [...orders, ...response.data]);
            setEndList(response?.data?.length < DEFAULT_PAGE_LIMIT)
            pageIdRef.current = pageId;
            setIsLoading(false)
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR);
        }
    }

    const handleToggleTab = (tab: number) => () => {
        setStatus(tab)
    }

    const handleLoadMore = () => {
        if (!endList) {
            getListOrder(pageIdRef.current + 1)
        }
    }

    const toggleShowModalConfirm = () => {
        setShowModalConfirm(!showModalConfirm)
    }

    const handleSelectAction = (item: order.OrderAdmin, type: number) => () => {
        setShowModalConfirm(true)
        setOrderSelected(item)
        setStatusSelected(type)
    }

    const handleConfirm = async () => {
        try {
            if (!isPress) {
                setIsPress(true)
                const response = await changeStatusOrderAdminApi({ order_id: orderSelected.order_id, status: statusSelected })
                setIsPress(false)
                showNotification(response.message, response.code)
                if (response.code === RESPONSE_CODE.SUCCESS) {
                    getListOrder();
                    setShowModalConfirm(false)
                    getCountAdmin();
                }
            }
        } catch (error) {
            setIsPress(false)
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR);
        }
    }

    const chooseProduct = (data: string) => () => {
        onPushEventBus(EventBusName.TOGGLE_MODAL_PRODUCT_VIEW, data)
    }

    useEffect(() => {
        getListOrder();
    }, [status])

    if (isLoading) return <Loading />

    return (
        <div className="manage-deposit-container">
            <ButtonGroup className='item-center mgt-2 wrap' style={{ display: 'flex', width: '100%' }}>
                <Button className={buttonGroupClass(TYPE_STATUS.NO_DELIVERY)} onClick={handleToggleTab(TYPE_STATUS.NO_DELIVERY)}>Chưa giao ({countData.totalSellNoDelivery})</Button>
                <Button className={buttonGroupClass(TYPE_STATUS.OVERTIME)} onClick={handleToggleTab(TYPE_STATUS.OVERTIME)}>Chưa giao quá 48h ({countData.totalSellOverTime})</Button>
                <Button className={buttonGroupClass(TYPE_STATUS.DELIVERY)} onClick={handleToggleTab(TYPE_STATUS.DELIVERY)}>Đã giao ({countData.totalSellDelivery})</Button>
                <Button className={buttonGroupClass(TYPE_STATUS.CANCEL)} onClick={handleToggleTab(TYPE_STATUS.CANCEL)}>Hủy đơn ({countData.totalSellCancel})</Button>
            </ButtonGroup>
            <InfiniteScroll
                loadMore={handleLoadMore}
                hasMore={!endList && !isLoading}
                loader={<Loading />}
                threshold={20}
            >
                {orders.map((item) => {
                    const isSelectShipVN = +item.ship_type === SHIP_TYPE.SHIP_VN;
                    const isCheckoutBalanceVN = ((+item.user_balance_checkout_type === 1 && +item.ship_type === 1) || +item.user_balance_checkout_type === 2);
                    return (
                        <div className="box-list-product-admin row" key={item.order_id}>
                            <div className="col-md-2 col-sm-12 col-xs-12 left-col-product-list-admin mgt">
                                <p className="bold">{convertDateTime(item.cr_date_time)}</p>
                                <div className="hr-product-list-admin" />
                                <TooltipUser
                                    data={item}
                                />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12 flex align-center mgt">
                                {/* <img
                                src={getAvatar(item.image)}
                                alt='img-product'
                                className="img-product-list-admin"
                            /> */}
                                <div className="mgl-2">
                                    <p className="text-large bold">{item.order_name} - {item.order_phone_or_email}</p>
                                    <p>Địa chỉ: <span className="bold">{item.order_address}</span></p>
                                    <p>Kiểu ship: <span className="bold">{SHIP_TYPE_NAME[item.ship_type]}</span></p>
                                    <div className="flex align-center">
                                        <p className="mgr">Tổng giá trị phải thanh toán:</p>
                                        <Cost className="bold" type={isSelectShipVN ? COST_TYPE.VN : COST_TYPE.JP} price={item.balance_total} />
                                    </div>
                                    <div className="flex align-center">
                                        <p className="mgr">Đã trừ ví:</p>
                                        <Cost className="bold" type={isCheckoutBalanceVN ? COST_TYPE.VN : COST_TYPE.JP} price={+item.balance_minus || '0'} />
                                    </div>
                                    <div className="flex align-center">
                                        <p className="mgr">Cần chuyển khoản:</p>
                                        <Cost className="bold" type={+item.cost_type} price={getBalanceCk(isSelectShipVN, isCheckoutBalanceVN, +item.cost_type, +item.balance_total, +item.balance_minus, +ratioBalance) || '0'} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 col-xs-12 mgt">
                                {[TYPE_STATUS.NO_DELIVERY, TYPE_STATUS.DELIVERY, TYPE_STATUS.OVERTIME].includes(status) &&
                                    <div
                                        className="flex flex-end align-center pointer"
                                    >
                                        {([TYPE_STATUS.NO_DELIVERY, TYPE_STATUS.OVERTIME].includes(status)) &&
                                            <Button
                                                className="outline mgr"
                                                onClick={handleSelectAction(item, TYPE_STATUS.DELIVERY)}
                                            >
                                                Giao hàng
                                            </Button>
                                        }
                                        <Button
                                            className="outline mgl"
                                            onClick={handleSelectAction(item, TYPE_STATUS.CANCEL)}
                                        >
                                            Huỷ đơn và hoàn tiền
                                        </Button>
                                    </div>
                                }
                                <div
                                    className="flex flex-end align-center pointer flex-wrap mgt-2"
                                >
                                    {item.carts?.map(i =>
                                        <div>
                                            <img
                                                alt="product-img"
                                                src={getAvatar(i.image)}
                                                className="product-img-log"
                                                onClick={chooseProduct(i.product_id)}
                                            />
                                            <p className="text-large text-center bold mgr mgl">x{i.amount}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </InfiniteScroll>
            <Modal
                {...PROP.MODAL}
                open={showModalConfirm}
                onCancel={toggleShowModalConfirm}
            >
                <p className="mgt-2 text-center">
                    Xác nhận đổi trạng thái đơn hàng thành <span className="bold">{TYPE_STATUS.DELIVERY === statusSelected ? 'Giao hàng' : 'Hủy đơn'}</span> ?
                </p>
                {TYPE_STATUS.CANCEL === statusSelected &&
                    <div className="flex item-center mgt-2">
                        <p className="bold">Sẽ hoàn lại</p>
                        <Cost className="mgl" type={+orderSelected.ship_type === SHIP_TYPE.SHIP_VN ? COST_TYPE.VN : COST_TYPE.JP} price={+orderSelected.balance_total} />
                        <p className="bold mgl">cho người mua?</p>
                    </div>
                }
                <div className="flex item-center mgt-2">
                    <p className="bold"></p>
                </div>
                <div className="item-center mgt-3">
                    <Button loading={isPress} onClick={handleConfirm}>Xác nhận</Button>
                </div>
            </Modal>
        </div>
    )
}
const mapStateToProps = (state: models.GlobalState) => ({
    countData: countDataSelector(state),
    ratioBalance: ratioBalanceSelector(state)
})

const mapDispatchToProps = {
    getCountAdmin: adminActionCreators.getCountAdmin
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageSell)