import { combineReducers } from "redux";
import user from "../../models/user";
import persistReducerUtil from "../../utils/common";
import { userActions, userActionTypes } from "../actions/user";
import config from "../../models/config";

export const USER_INITIAL_STATE: user.State = {
    userData: {
        user_id: '',
        avatar: '',
        phone_number_or_email: '',
        role_id: '1',
        is_block: '',
        cr_date_time: '',
        user_name: '',
        address: '',
        facebook: '',
        balance_vn: '',
        balance_jp: ''
    },
    showModalDeposit: false,
    showModalLogin: false,
    showModalChat: false,
    bankData: [],
    configs: [],
};

const userData = (
    state: user.User = USER_INITIAL_STATE.userData,
    action: userActionTypes.getUserDataSuccessActionType | userActionTypes.logoutActionType
) => {
    switch (action.type) {
        case userActions.GET_USER_DATA_SUCCESS:
            return {
                ...state,
                ...action.payload
            };
        case userActions.LOGOUT:
            return USER_INITIAL_STATE.userData;
        default:
            return state;
    }
}

const showModalDeposit = (
    state: boolean = USER_INITIAL_STATE.showModalDeposit,
    action: userActionTypes.toggleModalDepositActionType
) => {
    switch (action.type) {
        case userActions.TOGGLE_MODAL_DEPOSIT:
            return !state;
        default:
            return state;
    }
}

const showModalLogin = (
    state: boolean = USER_INITIAL_STATE.showModalLogin,
    action: userActionTypes.toggleModalLoginActionType
) => {
    switch (action.type) {
        case userActions.TOGGLE_MODAL_LOGIN:
            return !state;
        default:
            return state;
    }
}

const showModalChat = (
    state: boolean = USER_INITIAL_STATE.showModalChat,
    action: userActionTypes.toggleModalChatActionType
) => {
    switch (action.type) {
        case userActions.TOGGLE_MODAL_CHAT:
            return !state;
        default:
            return state;
    }
}

const bankData = (
    state: user.Bank[] = USER_INITIAL_STATE.bankData,
    action: userActionTypes.getBankDataSuccessActionType
) => {
    switch (action.type) {
        case userActions.GET_BANK_DATA_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}

const configs = (
    state: config.Config[] = USER_INITIAL_STATE.configs,
    action: userActionTypes.getListConfigSuccessActionType
) => {
    switch (action.type) {
        case userActions.GET_LIST_CONFIG_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}

const userReducers = combineReducers<user.State>({
    userData,
    showModalDeposit,
    showModalLogin,
    showModalChat,
    bankData,
    configs,
});

export default persistReducerUtil(
    'user',
    userReducers,
    ['userData'],
);
