import models from "../../models";
import config from "../../models/config";
import user from "../../models/user";

class UserActions {
    public readonly GET_USER_DATA = 'GET_USER_DATA';
    public readonly GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';
    public readonly GET_USER_DATA_FAILURE = 'GET_USER_DATA_FAILURE';

    public readonly GET_LIST_CONFIG = 'GET_LIST_CONFIG';
    public readonly GET_LIST_CONFIG_SUCCESS = 'GET_LIST_CONFIG_SUCCESS';
    public readonly GET_LIST_CONFIG_FAILURE = 'GET_LIST_CONFIG_FAILURE';

    public readonly TOGGLE_MODAL_DEPOSIT = 'TOGGLE_MODAL_DEPOSIT';
    public readonly TOGGLE_MODAL_LOGIN = 'TOGGLE_MODAL_LOGIN';
    public readonly TOGGLE_MODAL_CHAT = 'TOGGLE_MODAL_CHAT';

    public readonly GET_BANK_DATA = 'GET_BANK_DATA';
    public readonly GET_BANK_DATA_SUCCESS = 'GET_BANK_DATA_SUCCESS';
    public readonly GET_BANK_DATA_FAILURE = 'GET_BANK_DATA_FAILURE';

    public readonly LOGOUT = 'LOGOUT';
}

export declare namespace userActionTypes {
    type getUserDataActionType = models.TypedActionEmpty<typeof userActions.GET_USER_DATA>;
    type getUserDataSuccessActionType = models.TypedAction<typeof userActions.GET_USER_DATA_SUCCESS, user.User>;
    type getUserDataFailureActionType = models.TypedActionError<typeof userActions.GET_USER_DATA_FAILURE, string>;

    type getListConfigActionType = models.TypedActionEmpty<typeof userActions.GET_LIST_CONFIG>;
    type getListConfigSuccessActionType = models.TypedAction<typeof userActions.GET_LIST_CONFIG_SUCCESS, config.Config[]>;
    type getListConfigFailureActionType = models.TypedActionError<typeof userActions.GET_LIST_CONFIG_FAILURE, string>;

    type getBankDataActionType = models.TypedActionEmpty<typeof userActions.GET_BANK_DATA>;
    type getBankDataSuccessActionType = models.TypedAction<typeof userActions.GET_BANK_DATA_SUCCESS, user.Bank[]>;
    type getBankDataFailureActionType = models.TypedActionError<typeof userActions.GET_BANK_DATA_FAILURE, string>;

    type toggleModalDepositActionType = models.TypedActionEmpty<typeof userActions.TOGGLE_MODAL_DEPOSIT>;
    type toggleModalLoginActionType = models.TypedActionEmpty<typeof userActions.TOGGLE_MODAL_LOGIN>;
    type toggleModalChatActionType = models.TypedActionEmpty<typeof userActions.TOGGLE_MODAL_CHAT>;

    type logoutActionType = models.TypedActionEmpty<typeof userActions.LOGOUT>;
}

class UserActionsCreators {
    public getUserData = (): userActionTypes.getUserDataActionType => ({ type: userActions.GET_USER_DATA });
    public getUserDataSuccess = (payload: user.User): userActionTypes.getUserDataSuccessActionType => ({ type: userActions.GET_USER_DATA_SUCCESS, payload });
    public getUserDataFailure = (error: string): userActionTypes.getUserDataFailureActionType => ({ type: userActions.GET_USER_DATA_FAILURE, error });

    public getListConfig = (): userActionTypes.getListConfigActionType => ({ type: userActions.GET_LIST_CONFIG });
    public getListConfigSuccess = (payload: config.Config[]): userActionTypes.getListConfigSuccessActionType => ({ type: userActions.GET_LIST_CONFIG_SUCCESS, payload });
    public getListConfigFailure = (error: string): userActionTypes.getListConfigFailureActionType => ({ type: userActions.GET_LIST_CONFIG_FAILURE, error });

    public getBankData = (): userActionTypes.getBankDataActionType => ({ type: userActions.GET_BANK_DATA });
    public getBankDataSuccess = (payload: user.Bank[]): userActionTypes.getBankDataSuccessActionType => ({ type: userActions.GET_BANK_DATA_SUCCESS, payload });
    public getBankDataFailure = (error: string): userActionTypes.getBankDataFailureActionType => ({ type: userActions.GET_BANK_DATA_FAILURE, error });


    public toggleModalDeposit = () => ({ type: userActions.TOGGLE_MODAL_DEPOSIT });
    public toggleModalLogin = () => ({ type: userActions.TOGGLE_MODAL_LOGIN });
    public toggleModalChat = () => ({ type: userActions.TOGGLE_MODAL_CHAT });

    public logout = (): userActionTypes.logoutActionType => ({ type: userActions.LOGOUT });
}

export const userActions = new UserActions();
export const userActionCreators = new UserActionsCreators();