import { combineReducers } from "redux";
import user from "../../models/user";
import persistReducerUtil from "../../utils/common";
import admin from "../../models/admin";
import { adminActions, adminActionTypes } from "../actions/admin";

export const ADMIN_INITIAL_STATE: admin.State = {
    countData: {
        totalProduct: '0',
        totalDeposit: '0',
        totalDepositDay: '0',
        totalDepositConfirm: '0',
        totalDepositOverDay: '0',
        totalChat: '0',
        totalChatNoReply: '0',
        totalChatReply: '0',
        totalSell: '0',
        totalSellNoDelivery: '0',
        totalSellDelivery: '0',
        totalSellCancel: '0',
        totalSellOverTime: '0',
        totalUser: '0',
    },
};

const countData = (
    state: admin.Count = ADMIN_INITIAL_STATE.countData,
    action: adminActionTypes.getCountDataSuccessActionType
) => {
    switch (action.type) {
        case adminActions.GET_COUNT_ADMIN_SUCCESS:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}

const adminReducers = combineReducers<admin.State>({
    countData
});

export default persistReducerUtil(
    'admin',
    adminReducers,
);
