import axios from 'axios';
import { END_POINTS } from '..';
import user from '../../models/user';
import models from '../../models';
import admin from '../../models/admin';
const qs = require('qs')

export const getListUserAdminApi  = (params: user.GetListUserAdminRequest) =>{
    let url = END_POINTS.getListUserAdmin;
	return axios.get(url, {params}).then((res: { data: user.ListUserAdminResponse }) => res.data);
}

export const toggleBlockUserApi = (params: user.ToggleBlockUserRequest) =>{
    const url = END_POINTS.toggleBlockUserAdmin;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}

export const resetPasswordAdminApi = (params: {user_id: string}) =>{
    const url = END_POINTS.resetPasswordAdmin;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}

export const updateUserAdminApi = (params: user.UpdateUserRequest) =>{
    const url = END_POINTS.updateUserAdmin;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}

export const getCountAllAdminApi = () =>{
    const url = END_POINTS.getCountAllAdmin;
	return axios.get(url).then((res: { data: admin.CountDataReponse }) => res.data);
}

export const changeBankApi = (params: user.Bank) =>{
    const url = END_POINTS.changeBank;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}