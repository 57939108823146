import { all, put, takeEvery } from 'redux-saga/effects';
import { userActionCreators, userActions } from '../actions/user';
import user from '../../models/user';
import { getBankDataApi, getUserDataApi } from '../../apis/user';
import Cookies from 'js-cookie';
import { KEY, MESSAGES, RESPONSE_CODE } from '../../utils/constants';
import { applyToken } from '../../utils/common';
import { adminActionCreators, adminActions } from '../actions/admin';
import admin from '../../models/admin';
import { getCountAllAdminApi } from '../../apis/admin/user';
import { getListConfigApi } from '../../apis/config';
import config from '../../models/config';

export default function* rootSaga() {
    yield all([
        takeEvery(userActions.GET_USER_DATA, getUserDataRuntime),
        takeEvery(userActions.GET_BANK_DATA, getBankRuntime),
        takeEvery(adminActions.GET_COUNT_ADMIN, getCountAdminRuntime),
        takeEvery(userActions.GET_LIST_CONFIG, getListConfigRuntime),
    ]);
}

function* getUserDataRuntime(){
    try {
        const response: user.UserResponse = yield getUserDataApi();
        if(response.code === 1){
            applyToken(response.token)
            yield put(userActionCreators.getUserDataSuccess(response.data));
        }
        else if(response.code === -3){
            Cookies.remove(KEY.TOKEN);
            localStorage.clear();
            const win: Window = window;
            win.location = "/";
        }
        else put(userActionCreators.getUserDataFailure(response.message));
    } catch (err) {
        yield put(userActionCreators.getUserDataFailure(MESSAGES.ERROR));
    }
}

function* getCountAdminRuntime(){
    try {
        const response: admin.CountDataReponse = yield getCountAllAdminApi();
        if(response.code === RESPONSE_CODE.SUCCESS){
            yield put(adminActionCreators.getCountAdminSuccess(response.data))
        }
    } catch (err) {
        yield put(adminActionCreators.getCountAdminFailure(MESSAGES.ERROR));
    }
}
function* getBankRuntime(){
    try {
        const response: user.BankResponse = yield getBankDataApi();
        if(response.code === RESPONSE_CODE.SUCCESS){
            yield put(userActionCreators.getBankDataSuccess(response.data))
        }
    } catch (err) {
        yield put(userActionCreators.getBankDataFailure(MESSAGES.ERROR));
    }
}

function* getListConfigRuntime(){
    try {
        const response: config.ConfigResponse = yield getListConfigApi();
        if(response.code === 1){
            yield put(userActionCreators.getListConfigSuccess(response.data));
        }
        else put(userActionCreators.getListConfigFailure(response.message));
    } catch (err) {
        yield put(userActionCreators.getListConfigFailure(MESSAGES.ERROR));
    }
}