import { Button, Modal } from "antd";
import { KEY, PROP, ROUTES } from "../../utils/constants";
import { connect } from "react-redux";
import { userActionCreators } from "../../redux/actions/user";
import Cookies from "js-cookie";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface Props extends RouteComponentProps{
    showModal: boolean;
    onClose: () => void;
    logout: typeof userActionCreators.logout;
}

const ModalLogout = (props: Props) => {
    const { showModal, onClose, logout, history } = props;

    const handleLogout = () =>{
        history.push(ROUTES.ROOT)
        logout();
        Cookies.remove(KEY.TOKEN);
        onClose();
    }

    return (
        <Modal
            {...PROP.MODAL}
            open={showModal}
            onCancel={onClose}
        >
            <div className="item-center">
                <p className="bold mgb-2 text-center text-large">Xác nhận đăng xuất?</p>
                <Button className="mgt-3" onClick={handleLogout}>Đăng xuất</Button>
            </div>
        </Modal>
    )
}

const mapDispatchToProps = {
    logout: userActionCreators.logout
}

export default connect(undefined, mapDispatchToProps)(withRouter(ModalLogout))