import axios from 'axios';
import { END_POINTS } from '..';
import models from '../../models';
import cart from '../../models/cart';
const qs = require('qs')

export const addToCartApi = (params: {product_id: string}) =>{
    const url = END_POINTS.addToCart;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}

export const getCartInfoApi = () =>{
    const url = END_POINTS.getCartInfo;
	return axios.get(url).then((res: { data: cart.CartUserResponse }) => res.data);
}

export const updateProductCartApi = (params: {cart_user_id: string, amount: string, is_select: string}) =>{
    const url = END_POINTS.updateProductCart;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}

export const deleteProductCartApi = (params: {cart_user_id: string}) =>{
    const url = END_POINTS.deleteProductCart;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}
