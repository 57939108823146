import React, { useEffect, useState } from "react"
import models from "../../../models"
import { showModalDepositSelector, userDataSelector } from "../../../redux/selectors/user"
import { connect } from "react-redux"
import user from "../../../models/user"
import { IMAGES } from "../../../assets"
import { userActionCreators } from "../../../redux/actions/user"
import { ROUTES } from "../../../utils/constants"
import ModalLogout from "../../modal/ModalLogout"
import { RouteComponentProps, useLocation, withRouter } from "react-router-dom"
import Icon from 'react-fontawesome'
import Chat from "../../chat/Chat"
import { adminActionCreators } from "../../../redux/actions/admin"
import { countDataSelector } from "../../../redux/selectors/admin"
import admin from "../../../models/admin"
import ModalChat from "../../modal/ModalChat"
import { Subscription } from "rxjs"
import EventBus, { BaseEventType, EventBusName } from "../../../services/event-bus"

const listHeaderRoute = [
    { title: 'Quản lý mẫu', route: ROUTES.MANAGE_PRODUCT, total: 'totalProduct' },
    { title: 'Quản lý nạp tiền', route: ROUTES.MANAGE_DEPOSIT, total: 'totalDepositDay' },
    { title: 'Tư vấn bán hàng', route: ROUTES.MANAGE_CHAT, total: 'totalChatNoReply'},
    { title: 'Quản lý đơn hàng', route: ROUTES.MANAGE_SELL, total: 'totalSellNoDelivery' },
    { title: 'Quản lý Khách hàng', route: ROUTES.MANAGE_USER, total: 'totalUser' },
]

interface Props extends RouteComponentProps {
    children: React.ReactNode;
    userData: user.User;
    getCountAdmin: typeof adminActionCreators.getCountAdmin
    countData: admin.Count | any
    getBankData: typeof userActionCreators.getBankData
}

const AdminLayout = React.memo((props: Props) => {
    const { userData, getCountAdmin, countData, getBankData } = props;
    const [chatGroupId, setChatGroupId] = useState('')

    const [showModalLogout, setShowModalLogout] = useState(false)

    const subScription = new Subscription();

    const route = useLocation();

    const toggleShowModalLogout = (e: React.MouseEvent<HTMLElement> | null = null) => {
        e?.stopPropagation()
        setShowModalLogout(!showModalLogout)
    }

    const handleChangeRoute = (router: string) => () => {
        props.history.push(router)
    }

    const generateClassSelected = (routeSelect: string) => {
        return route.pathname === routeSelect ? 'bold text-large' : ''
    }

    const onRegisterEventBus = () => {
        const sub = EventBus.getInstance().events.subscribe((res: BaseEventType) => {
            if (res.type === EventBusName.SET_CHAT_GROUP_ID_ADMIN) {
                setChatGroupId(res.payload)
            }
        });
        subScription.add(sub);
    };

    useEffect(() => {
        onRegisterEventBus();
        return () => {
            subScription?.unsubscribe?.();
        };
    }, []);

    useEffect(() =>{
        getCountAdmin()
        getBankData();
    }, [])

    return (
        <div className="app-content">
            <div className="header-container">
                <div className="header-button-container admin-header">
                    <div className="button-header" onClick={handleChangeRoute(ROUTES.ROOT)}>
                        <Icon
                            name="plus-circle"
                            className="add-product-icon"
                        />
                        <p className={`text-header ${generateClassSelected(ROUTES.ROOT)}`}>Thêm mẫu</p>
                    </div>
                    <div className="line-header" />
                    {listHeaderRoute.map((item, key) =>
                        <React.Fragment key={key}>
                            <div className="button-header" onClick={handleChangeRoute(item.route)}>
                                <p className={`text-header ${generateClassSelected(item.route)}`}>{item.title} ({countData[item.total]})</p>
                            </div>
                            <div className="line-header" />
                        </React.Fragment>
                    )}
                    <div className="button-header" onClick={handleChangeRoute(ROUTES.USER)}>
                        <p className={`text-header ${generateClassSelected(ROUTES.USER)}`}>{userData.user_name}</p>
                        <img
                            alt="arrow"
                            src={IMAGES.ARROW_WHITE}
                            className="arrow-icon-logout"
                            onClick={toggleShowModalLogout}
                        />
                    </div>
                </div>
            </div>
            <div className='main-content'>
                {props.children}
            </div>
            <ModalLogout
                showModal={showModalLogout}
                onClose={toggleShowModalLogout}
            />
            <ModalChat
                chatGroupId={chatGroupId}
            />
        </div>
    )
})

const mapStateToProps = (state: models.GlobalState) => ({
    userData: userDataSelector(state),
    showModalDeposit: showModalDepositSelector(state),
    countData: countDataSelector(state),
})

const mapDispatchToProps = {
    toggleModalDeposit: userActionCreators.toggleModalDeposit,
    getCountAdmin: adminActionCreators.getCountAdmin,
    getBankData: userActionCreators.getBankData,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminLayout))