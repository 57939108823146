import { Button } from "antd";
import { useState, useEffect, useRef } from "react";
import Cost from "../components/common/Cost";
import { COST_TYPE, DEFAULT_PAGE_LIMIT, MESSAGES, RESPONSE_CODE, SHIP_TYPE, USER_BALANCE_CHECKOUT_TYPE } from "../utils/constants";
import { IMAGES } from "../assets";
import ModalEditUser from "../components/modal/ModalEditUser";
import { connect } from "react-redux";
import models from "../models";
import { ratioBalanceSelector, userDataSelector } from "../redux/selectors/user";
import user from "../models/user";
import { convertDate, convertDateTime, getAvatar, getBalanceCk } from "../utils/common";
import ModalChangePassword from "../components/modal/ModalChangePassword";
import { userActionCreators } from "../redux/actions/user";
import ModalWithdraw from "../components/modal/ModalWithdraw";
import { showNotification } from "../utils/notification";
import { getListLogUserApi } from "../apis/user";
import InfiniteScroll from "react-infinite-scroller";
import Loading from "../components/common/Loading";
import moment from "moment";
import { EventBusName, onPushEventBus } from "../services/event-bus";

interface Props {
    userData: user.User;
    toggleModalDeposit: typeof userActionCreators.toggleModalDeposit;
    getUserData: typeof userActionCreators.getUserData;
    ratioBalance: string;
}

const User = (props: Props) => {
    const { userData, toggleModalDeposit, getUserData, ratioBalance } = props;
    const [showModalEditUser, setShowModalEditUser] = useState(false)
    const [showModalChangePassword, setShowModalChangePassword] = useState(false)
    const [showModalWithdraw, setShowModalWithdraw] = useState(false)

    const [logs, setLogs] = useState<user.LogUser[]>([])
    const [endList, setEndList] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState(true)

    const pageIdRef = useRef<number>(1)

    const toggleShowModalEditUser = () => {
        setShowModalEditUser(!showModalEditUser)
    }

    const toggleShowModalChangePassword = () => {
        setShowModalChangePassword(!showModalChangePassword)
    }

    const toggleShowModalWithdraw = () => {
        setShowModalWithdraw(!showModalWithdraw)
    }

    const handleClickLink = (link: string) => () => {
        window.open(link, '_blank')
    }

    const getListLog = async (pageId = 1) => {
        try {
            const response = await getListLogUserApi({ page_id: pageId })
            setLogs(pageId === 1 ? response.data : [...logs, ...response.data]);
            setEndList(response?.data?.length < DEFAULT_PAGE_LIMIT)
            pageIdRef.current = pageId;
            setIsLoading(false)
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR);
        }
    }

    const handleLoadMore = () => {
        if (!endList) {
            getListLog(pageIdRef.current + 1)
        }
    }

    const isAdmin = userData.role_id === '2'

    const chooseProduct = (data: string) => () => {
        onPushEventBus(EventBusName.TOGGLE_MODAL_PRODUCT_VIEW, data)
    }

    const renderTypeLog = (item: user.LogUser) => {
        switch (item.log_type) {
            case '1':
                return (
                    <>
                        <div className="col-md-2 col-sm-12 col-xs-12 left-col-product-list-admin">
                            <div>
                                <p>{moment(item.cr_date_time).format("HH:mm")}</p>
                                <p className="bold">{convertDate(item.cr_date_time)}</p>
                            </div>
                            <div className="hr-product-list-admin" />
                        </div>
                        <div className="col-md-8 col-sm-12 col-xs-12 flex align-center">
                            <div className="flex align-center">
                                <p className="text-large bold">{item.is_withdraw === '1' ? 'Rút tiền' : 'Nạp tiền'}:</p>
                                <Cost textClass="text-large bold" className="mgl" type={+item.balance_type} price={item.balance} />
                            </div>
                        </div>
                        {+item.is_approve === 0 &&
                        <div className="col-md-2 col-sm-12 col-xs-12 flex flex-end">
                            <div
                                className="flex flex-end align-center pointer"
                            >
                                <Button className="outline">Chờ xác nhận</Button>
                            </div>
                        </div>
                        }
                    </>
                )
            case '2':
                return (
                    <>
                        <div className="col-md-2 col-sm-12 col-xs-12 left-col-product-list-admin">
                            <div>
                                <p>{moment(item.cr_date_time).format("HH:mm")}</p>
                                <p className="bold">{convertDate(item.cr_date_time)}</p>
                            </div>
                            <div className="hr-product-list-admin" />
                        </div>
                        <div className="col-md-10 col-sm-12 col-xs-12 flex-column">
                            <div className="flex align-center">
                                <p className="text-large bold">{item.is_withdraw === '1' ? 'Rút tiền thành công: -' : 'Nạp tiền thành công: +'}</p>
                                <Cost textClass="text-large bold" className="mgl" type={+item.balance_type} price={item.balance} />
                            </div>
                            <div className="flex align-center mgt">
                                <p className="text-large bold">Số dư: </p>
                                <Cost textClass="text-large bold" className="mgl" type={+item.balance_type} price={item.balance_change} />
                            </div>
                        </div>
                    </>
                )
            case '3':
            case '4':
                const isSelectShipVN = +item.ship_type === SHIP_TYPE.SHIP_VN;
                const isCheckoutBalanceVN = ((+item.user_balance_checkout_type === 1 && +item.ship_type === 1) || +item.user_balance_checkout_type === 2);
                return (
                    <>
                        <div className="col-md-2 col-sm-12 col-xs-12 left-col-product-list-admin">
                            <div>
                                <p>{moment(item.cr_date_time).format("HH:mm")}</p>
                                <p className="bold">{convertDate(item.cr_date_time)}</p>
                            </div>
                            <div className="hr-product-list-admin" />
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12 flex flex-column justify-center">
                            {+item.balance_minus > 0 &&
                                <div className="flex align-center">
                                    <p className="text-large bold">Trừ ví: -</p>
                                    <Cost textClass="text-large bold" className="mgl" type={+item.balance_change_type} price={item.balance_minus} />
                                </div>
                            }
                            <div className="flex align-center mgt">
                                <p className="text-large bold">Chuyển khoản: </p>
                                <Cost textClass="text-large bold" className="mgl" type={+item.cost_type} price={getBalanceCk(isSelectShipVN, isCheckoutBalanceVN, +item.cost_type, +item.balance_total, +item.balance_minus, +ratioBalance) || '0'} />
                            </div>
                            {+item.balance_minus > 0 &&
                                <div className="flex align-center mgt">
                                    <p className="text-large bold">Số dư ví: </p>
                                    <Cost textClass="text-large bold" className="mgl" type={+item.balance_change_type} price={item.balance_change} />
                                </div>
                            }
                        </div>
                        <div className="col-md-6 col-sm-12 col-xs-12 flex flex-end">
                            <div
                                className="flex flex-end align-center pointer flex-wrap"
                            >
                                {item.orders?.map(i =>
                                    <>
                                        <p className="text-large bold mgr mgl">x{i.amount}</p>
                                        <img
                                            alt="product-img"
                                            src={getAvatar(i.image)}
                                            className="product-img-log"
                                            onClick={chooseProduct(i.product_id)}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                )
            case '5':
                return (
                    <>
                        <div className="col-md-2 col-sm-12 col-xs-12 left-col-product-list-admin">
                            <div>
                                <p>{moment(item.cr_date_time).format("HH:mm")}</p>
                                <p className="bold">{convertDate(item.cr_date_time)}</p>
                            </div>
                            <div className="hr-product-list-admin" />
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12 flex flex-column justify-center">
                            <p className="text-large bold">Đã giao hàng từ kho</p>
                            {/* <Cost textClass="text-large bold" className="mgl" type={+item.balance_type} price={+item.balance_type === COST_TYPE.VN ? item.balance_vn : item.balance_jp} /> */}
                        </div>
                        <div className="col-md-6 col-sm-12 col-xs-12 flex flex-end">
                            <div
                                className="flex flex-end align-center pointer flex-wrap"
                            >
                                {item.orders?.map(i =>
                                    <>
                                        <p className="text-large bold mgr mgl">x{i.amount}</p>
                                        <img
                                            alt="product-img"
                                            src={getAvatar(i.image)}
                                            className="product-img-log"
                                            onClick={chooseProduct(i.product_id)}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                )
            case '6':
                return (
                    <>
                        <div className="col-md-2 col-sm-12 col-xs-12 left-col-product-list-admin">
                            <div>
                                <p>{moment(item.cr_date_time).format("HH:mm")}</p>
                                <p className="bold">{convertDate(item.cr_date_time)}</p>
                            </div>
                            <div className="hr-product-list-admin" />
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12 flex flex-column justify-center">
                            <div className="flex align-center">
                                <p className="text-large bold">Hủy đơn: +</p>
                                <Cost textClass="text-large bold" className="mgl" type={+item.balance_change_type} price={item.balance_total} />
                            </div>
                            <div className="flex align-center mgt">
                                <p className="text-large bold">Số dư: </p>
                                <Cost textClass="text-large bold" className="mgl" type={+item.balance_change_type} price={item.balance_change} />
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 col-xs-12 flex flex-end">
                            <div
                                className="flex flex-end align-center pointer flex-wrap"
                            >
                                {item.orders?.map(i =>
                                    <>
                                        <p className="text-large bold mgr mgl">x{i.amount}</p>
                                        <img
                                            alt="product-img"
                                            src={getAvatar(i.image)}
                                            className="product-img-log"
                                            onClick={chooseProduct(i.product_id)}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                )
        }
        return null
    }

    useEffect(() => {
        getListLog()
    }, [])

    return (
        <>
            <div className="row">
                <div className={isAdmin ? "col-md-12 col-sm-12 col-xs-12 user-col-container" : "col-md-6 col-sm-12 col-xs-12 user-col-container"}>
                    <div className="box flex align-center">
                        <img
                            alt="avatar-user"
                            src={getAvatar(userData.avatar)}
                            className="avatar-user"
                        />
                        <div className="row max-width">
                            <div className="col-md-9 col-sm-12 col-xs-12">
                                <div className="user-info-container">
                                    <p className="text-large bold">{userData.user_name}</p>
                                    <p>SĐT hoặc Hòm thư:  {userData.phone_number_or_email}</p>
                                    <p>Địa chỉ: {userData.address}</p>
                                    <p className="pointer" onClick={handleClickLink(userData.facebook)}>
                                        FB: <u>{userData.facebook}</u>
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-12 col-xs-12 flex-column flex-end">
                                <div className="box-justify-info-user">
                                    <div className="flex align-center pointer" onClick={toggleShowModalEditUser}>
                                        <img
                                            src={IMAGES.EDIT}
                                            className="edit-user-img mgt"
                                            alt="edit-user"
                                        />
                                        <p className="mgl mgt">Sửa</p>
                                    </div>
                                    <Button className="outline mgt" onClick={toggleShowModalChangePassword}>Đổi mật khẩu</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {!isAdmin &&
                    <div className="col-md-6 col-sm-12 col-xs-12 user-col-container">
                        <div className="row max-width box max-height">
                            <div className="col-md-6 col-sm-12 col-xs-12">
                                <div className="box-cost-user-container">
                                    <div className="flex">
                                        <Cost type={COST_TYPE.VN} price={userData.balance_vn} textClass="text-large bold" />
                                    </div>
                                    <div className="flex">
                                        <Cost type={COST_TYPE.JP} price={userData.balance_jp} textClass="text-large bold" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 col-xs-12 flex-column flex-end">
                                <div className="box-justify-info-user">
                                    <Button className="btn-width-14" onClick={toggleModalDeposit}>Nạp thêm</Button>
                                    <Button className="outline btn-width-14" onClick={toggleShowModalWithdraw}>Rút tiền</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <InfiniteScroll
                loadMore={handleLoadMore}
                hasMore={!endList && !isLoading}
                loader={<Loading />}
                threshold={20}
            >
                {logs.map(item =>
                    <div className="box-list-log-user row" key={item.log_id}>
                        {renderTypeLog(item)}
                    </div>
                )}
            </InfiniteScroll>
            <ModalEditUser
                showModal={showModalEditUser}
                onClose={toggleShowModalEditUser}
            />
            <ModalChangePassword
                showModal={showModalChangePassword}
                onClose={toggleShowModalChangePassword}
            />
            <ModalWithdraw
                showModal={showModalWithdraw}
                onClose={toggleShowModalWithdraw}
            />
        </>
    )
}

const mapStateToProps = (state: models.GlobalState) => ({
    userData: userDataSelector(state),
    ratioBalance: ratioBalanceSelector(state)
})

const mapDispatchToProps = {
    toggleModalDeposit: userActionCreators.toggleModalDeposit,
    getUserData: userActionCreators.getUserData,
}

export default connect(mapStateToProps, mapDispatchToProps)(User);