export const IMAGES = {
    DELETE: require('./images/delete.png'),
    USER_PLACEHOLDER: require('./images/user-placeholder.png'),
    VN_FLAG: require('./images/vn-flag.png'),
    JP_FLAG: require('./images/jp-flag.png'),
    ARROW_WHITE: require('./images/arrow-white.png'),
    EDIT: require('./images/edit.png'),
    CHAT: require('./images/chat.png'),
    UPLOAD_AVATAR: require('./images/upload_avatar.png'),
    LOGO: require('./images/logo.png'),
    FB: require('./images/fb.png'),
    MESSENGER: require('./images/messenger.png'),
}

export const LOTTIE = {
    LOADING: require('./lottie/loading.json'),
    EMPTY: require('./lottie/empty.json'),
    COMPLETE: require('./lottie/complete.json'),
}