import React from 'react';
import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import models from '../../models';
import { userDataSelector } from '../../redux/selectors/user';
import user from '../../models/user';
import UserRoute from './UserRoute';
import AdminRoute from './AdminRoute';

type Props = {
    userData: user.User;
}

const RouteList = React.memo((props: Props) => {
    const { userData } = props;
    return (
        <Switch>
            {userData.role_id === '2'? <AdminRoute /> : <UserRoute />}
        </Switch>
    )
})

const mapStateToProps = (state: models.GlobalState) =>({
    userData: userDataSelector(state),
})

export default connect(mapStateToProps)(RouteList);

