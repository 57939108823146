import axios from 'axios';
import { END_POINTS } from '..';
import models from '../../models';
import cart from '../../models/cart';
import config from '../../models/config';
const qs = require('qs')

export const getListConfigApi = () =>{
    const url = END_POINTS.getListConfig;
	return axios.get(url).then((res: { data: config.ConfigResponse }) => res.data);
}