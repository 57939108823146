import { COST_TYPE, KEY, URI_PATH } from './constants';
import Cookies from 'js-cookie';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import { Reducer } from 'redux';
import { showNotification } from './notification';
import moment from 'moment';
import { IMAGES } from '../assets';
import Axios from 'axios';
import product from '../models/product';

export const isExistToken = () => {
    let token = Cookies.get(KEY.TOKEN);
    return token !== undefined;
}

export default function persistReducerUtil(key: string, reducer: Reducer, whitelist?: string[]): Reducer {
    const persistConfig = {
        key,
        storage,
        whitelist,
        debug: process.env.NODE_ENV !== 'production',
    };

    return persistReducer(persistConfig, reducer);
}

export const openNewTab = (link: string) => {
    window.open(link, '_blank')
}

export const getImage64 = (file: File, callBack: (data: string) => void, max_size = 1366) => {
    if (file) {
        let typeFile = file.type.replace('image/', '');
        let whiteList = ['jpeg', 'jpg', 'png', 'bmp'];
        if (whiteList.indexOf(typeFile) === -1) return showNotification('Tệp tin phải là ảnh có định dạng , jpeg/jpg/png/bmp', 0);
        let reader = new FileReader();
        reader.addEventListener("load", function (readerEvent) {
            let image = new Image();
            //ts-ignore
            image.src = (readerEvent?.target?.result || '') as string;
            image.onload = function (imageEvent) {
                // Resize the image
                let canvas = document.createElement('canvas'),// TODO : pull max size from a site config
                    width = image.width,
                    height = image.height;
                if (width > height) {
                    if (width > max_size) {
                        height *= max_size / width;
                        width = max_size;
                    }
                } else {
                    if (height > max_size) {
                        width *= max_size / height;
                        height = max_size;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                canvas?.getContext?.('2d')?.drawImage(image, 0, 0, width, height);
                let dataUrl = canvas.toDataURL('image/jpeg');
                let image64 = dataUrl;
                if (image64.length > 0) callBack(image64);
            }
            //
        }, false);
        if (file) reader.readAsDataURL(file);
    }
}

export const dataURLtoFile = (dataurl: string, filename: string) => {
    var arr = dataurl.split(','),
        //@ts-ignore
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
}

export const isBase64Image = (str: string) => {
    return str?.includes('data:image')
}

export const convertDate = (date?: string | Date) => {
    return moment(date).isValid() && date ? moment(date).format("DD/MM/YYYY") : ''
}

export const convertDateTime = (date?: string | Date) => {
    return moment(date).isValid() ? moment(date).format("DD/MM/YYYY HH:mm") : ''
}

export const getAvatar = (avatar: string) => {
    if (!avatar) return IMAGES.USER_PLACEHOLDER;
    return isBase64Image(avatar) ? avatar : `${URI_PATH}/${avatar}`
}

export const isTouch = () => {
    //@ts-ignore
    return ('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
}

export const uuidv4 = () => {
    //@ts-ignore
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export const getYoutubeId = (url: string) => {
    var p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    var matches = url.match(p);
    if (matches) {
        return matches[1];
    }
    return false;
}

export const upperFirst = (string: string) => {
    try {
        return string.charAt(0).toUpperCase() + string.slice(1);
    } catch (error) {
        return ''
    }
}

export const applyToken = (token?: string) => {
    Axios.defaults.headers.common['Auth'] = token;
    Cookies.set(KEY.TOKEN, token!, { expires: 365 });
}

export const formatDecimal = (value: string | number = '0') => {
    value = value.toString();
    value = value.replace(/\,/g, '');
    while (value.length > 1 && value[0] == '0' && value[1] != '.') value = value.substring(1);
    if (value != '' && value != '0') {
        if (value[value.length - 1] != '.') {
            if (value.indexOf('.000') > 0) value = value.substring(0, value.length - 3);
            value = addCommas(value);
            return value;
        }
        else return value;
    }
    else return '';
}

const addCommas = (nStr: string) => {
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}

export const getSymbolBalance = (type: number) =>{
    return type === COST_TYPE.VN ? 'đ' : '¥';
}

export const replaceCost = (cost: string, isInt: boolean = false) => {
    cost = cost.toString().replace(/\,/g, '');
    if (cost == '') cost = '0';
    if (isInt) return parseInt(cost);
    else return parseFloat(cost);
}

export const array_move = (arr: Array<any>, old_index: number, new_index: number) => {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
};

export const calcBalanceJP = (balance: string| number, ratioBalance: string) =>{
    return Math.round(+balance / +ratioBalance)
}

export const roundBalance = (balance: number, costType: number) =>{
    if(costType === COST_TYPE.JP){
        return Math.round(balance < 10 ? 0 : balance)
    }
    else{
        return Math.round(balance < 1000 ? 0 : balance)
    }
}

export const getBalanceCk = (isSelectShipVN: boolean, isCheckoutBalanceVN: boolean, costType: number, balanceTotal: number, balanceMinus: number, ratioBalance: number) =>{
    if(isSelectShipVN){
        if(isCheckoutBalanceVN){
            if(costType === COST_TYPE.VN){
                return roundBalance(balanceTotal - balanceMinus, COST_TYPE.VN);
            }
            return roundBalance((balanceTotal - balanceMinus)/ +ratioBalance, COST_TYPE.JP)
        }
        else{
            if(costType === COST_TYPE.VN){
                return roundBalance((balanceTotal / +ratioBalance - balanceMinus) * +ratioBalance, COST_TYPE.VN);
            }
            return roundBalance(balanceTotal / +ratioBalance - balanceMinus, COST_TYPE.JP)
        }
    }
    else {
        if(isCheckoutBalanceVN){
            if(costType === COST_TYPE.VN){
                return roundBalance(balanceTotal *+ratioBalance - balanceMinus, COST_TYPE.VN);
            }
            return roundBalance((balanceTotal *+ratioBalance - balanceMinus) / +ratioBalance, COST_TYPE.JP);
        }
        else {
            if(costType === COST_TYPE.VN){
                return roundBalance((balanceTotal - balanceMinus) * +ratioBalance, COST_TYPE.VN);
            }
            return roundBalance(balanceTotal - balanceMinus, COST_TYPE.JP);
        }
    }
}