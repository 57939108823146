import { createSelector } from 'reselect';
import models from '../../models';
import admin from '../../models/admin';
import { ADMIN_INITIAL_STATE } from '../reducers/admin';

const adminSelector = (state: models.GlobalState) => state.admin;

export const countDataSelector = createSelector(
    adminSelector,
    data => data.countData as admin.Count ?? ADMIN_INITIAL_STATE.countData
)