import { Button, Modal } from "antd";
import { COST_TYPE, MESSAGES, PROP, RESPONSE_CODE } from "../../utils/constants";
import { connect } from "react-redux";
import { userActionCreators } from "../../redux/actions/user";
import { useRef, useState } from "react";
import { IMAGES } from "../../assets";
import { formatDecimal, getSymbolBalance, replaceCost } from "../../utils/common";
import { showNotification } from "../../utils/notification";
import { depositApi } from "../../apis/deposit";
import BoxCk from "../common/BoxCk";

type Props = {
    showModal: boolean;
    onClose: () => void;
}

const STEP = {
    INPUT_BALANCE: 1,
    CONFIRM: 2
}

const ModalDeposit = (props: Props) => {
    const { showModal, onClose } = props;
    const [costType, setCostType] = useState<number>(COST_TYPE.VN)
    const [step, setStep] = useState(STEP.INPUT_BALANCE)
    const [inputBalanceVN, setInputBalanceVN] = useState('')
    const [inputBalanceJP, setInputBalanceJP] = useState('')
    const inputBalanceVNRef = useRef<HTMLInputElement>(null)
    const inputBalanceJPRef = useRef<HTMLInputElement>(null)
    const [isPress, setIsPress] = useState(false)

    const handleSelectCostType = (type: number) => () => {
        setCostType(type)
    }

    const handleClickBalanceVN = () => {
        inputBalanceVNRef.current?.focus();
    }

    const handleClickBalanceJP = () => {
        inputBalanceJPRef.current?.focus();
    }

    const toggleStep = () =>{
        setStep(step === STEP.INPUT_BALANCE ? STEP.CONFIRM : STEP.INPUT_BALANCE)
    }

    const balance = costType === COST_TYPE.VN ? replaceCost(inputBalanceVN) : replaceCost(inputBalanceJP)

    const handleContinue = () =>{
        if(balance > 0){
            toggleStep()
        }
        else showNotification('Số tiền không được để trống', RESPONSE_CODE.ERROR)
    }

    const handleDeposit = async() =>{
        try {
            if(!isPress){
                setIsPress(true);
                const response = await depositApi({balance, balance_type: costType})
                setIsPress(false)
                showNotification(response.message, response.code)
                if(response.code === RESPONSE_CODE.SUCCESS){
                    setStep(STEP.INPUT_BALANCE);
                    setInputBalanceVN('')
                    setInputBalanceJP('')
                    onClose();
                }
            }
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
            setIsPress(false)
        }
    }

    const renderStep = () => {
        if (step === STEP.INPUT_BALANCE) {
            return (
                <>
                    <div className="flex align-center mgt" onClick={handleClickBalanceVN}>
                        <input
                            type="radio"
                            checked={costType === COST_TYPE.VN}
                            className="mgr-2"
                            onClick={handleSelectCostType(COST_TYPE.VN)}
                        />
                        <div className="input-deposit-container">
                            <img
                                className="flag-img"
                                alt="flag"
                                src={IMAGES.VN_FLAG}
                            />
                            <input
                                className="input-deposit"
                                ref={inputBalanceVNRef}
                                onFocus={handleSelectCostType(COST_TYPE.VN)}
                                value={inputBalanceVN}
                                onChange={({ target: { value } }) => setInputBalanceVN(formatDecimal(value))}
                            />
                            <p className="bold">đ</p>
                        </div>
                    </div>
                    <div className="flex align-center mgt">
                        <input
                            type="radio"
                            checked={costType === COST_TYPE.JP}
                            className="mgr-2"
                            onClick={handleSelectCostType(COST_TYPE.JP)}
                        />
                        <div className="input-deposit-container" onClick={handleClickBalanceJP}>
                            <img
                                className="flag-img"
                                alt="flag"
                                src={IMAGES.JP_FLAG}
                            />
                            <input
                                className="input-deposit"
                                ref={inputBalanceJPRef}
                                onFocus={handleSelectCostType(COST_TYPE.JP)}
                                value={inputBalanceJP}
                                onChange={({ target: { value } }) => setInputBalanceJP(formatDecimal(value))}
                            />
                            <p className="bold">¥</p>
                        </div>
                    </div>
                    <div className="item-center mgt-3" onClick={handleContinue}>
                        <Button className="outline">Tiếp tục</Button>
                    </div>
                </>
            )
        }
        return(
            <>
                <div className="box item-center">
                    <p className="text-large text-center mgt-2 mgb-2">Số tiền {formatDecimal(balance)} {getSymbolBalance(costType)}</p>
                </div>
                <BoxCk typeBank={costType === COST_TYPE.VN ? 0 : 1} />
                <div className="item-center mgt-3">
                    <Button className="outline" onClick={handleDeposit} loading={isPress}>Xác nhận đã chuyển khoản</Button>
                    <p className="mgt-3" onClick={toggleStep}>
                        <u>Quay lại</u>
                    </p>
                </div>
            </>
        )
    }

    return (
        <Modal
            {...PROP.MODAL}
            open={showModal}
            onCancel={onClose}
            zIndex={99999}
        >
            {renderStep()}
        </Modal>
    )
}

export default connect(undefined)(ModalDeposit)