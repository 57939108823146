import axios from 'axios';
import { END_POINTS } from '..';
import product from '../../models/product';
import deposit from '../../models/deposit';
import models from '../../models';
import qs from 'qs';

export const getListDepositAdminApi  = (params: deposit.GetListDepositAdminRequest) =>{
    let url = END_POINTS.getListDepositAdmin;
	return axios.get(url, {params}).then((res: { data: deposit.DepositResponse }) => res.data);
}

export const confirmDepositAdminApi  = (params: {deposit_id: string}) =>{
    let url = END_POINTS.confirmDepositAdmin;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}
