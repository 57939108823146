import models from "../../models";
import admin from "../../models/admin";

class AdminActions {
    public readonly GET_COUNT_ADMIN = 'GET_COUNT_ADMIN';
    public readonly GET_COUNT_ADMIN_SUCCESS = 'GET_COUNT_ADMIN_SUCCESS';
    public readonly GET_COUNT_ADMIN_FAILURE = 'GET_COUNT_ADMIN_FAILURE';
}


export declare namespace adminActionTypes {
    type getCountDataActionType = models.TypedActionEmpty<typeof adminActions.GET_COUNT_ADMIN>;
    type getCountDataSuccessActionType = models.TypedAction<typeof adminActions.GET_COUNT_ADMIN_SUCCESS, admin.Count>;
    type getCountDataFailureActionType = models.TypedActionError<typeof adminActions.GET_COUNT_ADMIN_FAILURE, string>;
}

class AdminActionsCreators {
    public getCountAdmin = (): adminActionTypes.getCountDataActionType => ({ type: adminActions.GET_COUNT_ADMIN });
    public getCountAdminSuccess = (payload: admin.Count): adminActionTypes.getCountDataSuccessActionType => ({ type: adminActions.GET_COUNT_ADMIN_SUCCESS, payload });
    public getCountAdminFailure = (error: string): adminActionTypes.getCountDataFailureActionType => ({ type: adminActions.GET_COUNT_ADMIN_FAILURE, error });
}

export const adminActions = new AdminActions();
export const adminActionCreators = new AdminActionsCreators();