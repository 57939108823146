import axios from 'axios';
import { END_POINTS } from '..';
import models from '../../models';
import qs from 'qs';
import order from '../../models/order';

export const getListOrderAdminApi  = (params: order.GetListOrderAdminRequest) =>{
    let url = END_POINTS.getListOrderAdmin;
	return axios.get(url, {params}).then((res: { data: order.OrderAdminResponse }) => res.data);
}

export const changeStatusOrderAdminApi  = (params: {order_id: string, status: number}) =>{
    let url = END_POINTS.changeStatusOrder;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}