import React, { useEffect, useRef, useState } from "react"
import { Modal } from "antd"
import { MESSAGES, PROP, RESPONSE_CODE } from "../../utils/constants"
import FsLightbox from "fslightbox-react"
import Icon from 'react-fontawesome'
import Emoji from "../common/Emoji";
import chat from "../../models/chat"
import { showModalChatSelector, userDataSelector } from "../../redux/selectors/user"
import models from "../../models"
import { connect } from "react-redux"
import TooltipUser from "../common/TooltipUser"
import { getAvatar, getImage64 } from "../../utils/common"
import { showNotification } from "../../utils/notification"
import user from "../../models/user"
import { getListChatApi, sendChatApi } from "../../apis/chat"
import Loading from "../common/Loading"
import { userActionCreators } from "../../redux/actions/user"
import { EventBusName, onPushEventBus } from "../../services/event-bus"

type Props = {
    userData: user.User;
    showModalChat: boolean;
    toggleModalChat: typeof userActionCreators.toggleModalChat;
    chatGroupId?: string;
}

const ModalChat = (props: Props) => {
    const [inputChat, setinputChat] = useState<string>('')
    const [showEmoji, setShowEmoji] = useState<boolean>(false)
    const [isSend, setIsSend] = useState<boolean>(false)

    const [listChat, setListChat] = useState<chat.Chat[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [imagePreview, setImagePreview] = useState<string>('')

    const { userData, showModalChat, toggleModalChat, chatGroupId } = props;

    const inputImageRef = useRef<HTMLInputElement>(null)

    const toggleShowEmoji = () => setShowEmoji(!showEmoji)

    const refChat = useRef<HTMLDivElement>(null)

    const handleSend = async (message: string, is_image: string = '') => {
        try {
            setIsSend(true)
            const response = await sendChatApi({ message, is_image, chat_group_id: chatGroupId })
            setIsSend(false)
            setShowEmoji(false)
            if (response.code === RESPONSE_CODE.SUCCESS) {
                setinputChat('')
                const dataSend: chat.Chat = {
                    chat_id: response.chatId,
                    user_id: userData.user_id,
                    message: is_image === '' ? message : response.imageUrl,
                    type: is_image === '' ? '0' : '1',
                    list_user_id_read: [userData.user_id],
                    cr_date_time: 'Vừa xong',
                    avatar: userData.avatar,
                    user_name: userData.user_name,
                    is_advise: '0'
                };
                setListChat([
                    dataSend,
                    ...listChat,
                ])
                // socket.emit('send_chat_work_happyidem', dataSend)
            }
            else {
                showNotification(response.message, response.code);
            }
        } catch (error) {
            setIsSend(false)
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
        }
    }

    const sendChat = () => {
        const input = inputChat.trim();
        if (!isSend && input !== '') {
            handleSend(inputChat);
        }
    }

    const handleUploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event?.target?.files?.[0] as File;
        getImage64(file, image64 => {
            handleSend('', image64);
        });
        if (inputImageRef.current) inputImageRef.current.value = '';
    }

    const getListChat = async () => {
        try {
            const response = await getListChatApi({chat_group_id: chatGroupId})
            if (response.code === RESPONSE_CODE.SUCCESS) {
                setListChat(response.data)
                setIsLoading(false)
            }
            else showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
        }
    }

    const showPreviewImage = (image: string) => () => {
        setImagePreview(getAvatar(image))
    }

    const handlePickEmoji = (event: any) => {
        const emoji = event.target.firstChild.nodeValue;
        setinputChat(inputChat + emoji)
    }

    const chooseProduct = (data: string) => () =>{
        onPushEventBus(EventBusName.TOGGLE_MODAL_PRODUCT_VIEW, data)
    }

    useEffect(() => {
        if (showModalChat) {
            getListChat()
        }
        else {
            refChat.current?.scrollTo(0, 0)
        }
    }, [showModalChat])

    return (
            <Modal
                {...PROP.MODAL}
                open={showModalChat}
                className="modal-chat"
                onCancel={toggleModalChat}
                zIndex={99999}
            >
                <>
                    {isLoading ? <Loading />
                        :
                        <>
                            <div
                                className='box-chat-work'
                                ref={refChat}
                            >
                                {listChat.map((item) => {
                                    const isUserChat = item.user_id === userData.user_id;
                                    return (
                                        <React.Fragment key={item.chat_id}>
                                            <div className={isUserChat ? "chat-work-message-guest" : "chat-work-message-user"}>
                                                <TooltipUser data={item} className='avatar-message' />
                                                <div className={isUserChat ? "chat-work-message-item-user" : "chat-work-message-item-guest"}>
                                                    <div>
                                                        {item.is_advise === '1'
                                                            ?
                                                            <>
                                                                <img
                                                                    src={getAvatar(item.product?.image!)}
                                                                    className='product-view-img-select'
                                                                    alt='imagechat'
                                                                    onClick={chooseProduct(item.product?.product_id!)}
                                                                />
                                                                <p className="mgt text-center">Tư vấn: {item.product?.name}</p>
                                                            </>
                                                            :
                                                            <>
                                                                {item.type === '1' ?
                                                                    <div className='item-center'>
                                                                        <img
                                                                            src={getAvatar(item.message)}
                                                                            className='image-chat pointer'
                                                                            onClick={showPreviewImage(item.message)}
                                                                            alt='imagechat'
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <p className="mgb-0">{item.message}</p>
                                                                }
                                                            </>
                                                        }
                                                        <p className={isUserChat ? 'chat-time': 'chat-time-guest'}>{item.cr_date_time}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )
                                })}
                            </div>
                            <div className='input-chat-work-container'>
                                <input
                                    className='input-chat-work bg-transparent'
                                    placeholder='Nhập nôi dung'
                                    value={inputChat}
                                    onChange={({ target: { value } }) => setinputChat(value)}
                                    onFocus={() => setShowEmoji(false)}
                                    onKeyDown={e => {
                                        if (e.keyCode === 13) {
                                            sendChat();
                                            e.preventDefault();
                                        }
                                    }}
                                />
                                <Icon
                                    name='smile-o'
                                    className={'emoji-chat-work-select ' + (showEmoji ? 'success' : null)}
                                    onClick={toggleShowEmoji}
                                />
                                <Icon
                                    name='picture-o'
                                    className='emoji-chat-work-select'
                                    onClick={() => inputImageRef.current?.click()}
                                />
                                <Icon
                                    name='paper-plane'
                                    className='emoji-chat-work-select'
                                    style={{ marginRight: '2vh' }}
                                    onClick={sendChat}
                                />
                                {showEmoji &&
                                    <Emoji onPick={handlePickEmoji} onEndPick={() => setShowEmoji(true)} />
                                }
                            </div>
                            <input
                                type='file'
                                accept='image/*'
                                hidden={true}
                                ref={inputImageRef}
                                onChange={handleUploadImage}
                            />
                            {imagePreview &&
                                <FsLightbox
                                    toggler={imagePreview !== ''}
                                    sources={[imagePreview]}
                                    disableLocalStorage={true}
                                    openOnMount={true}
                                    onClose={() => setImagePreview('')}
                                    key={0}
                                    type='image'
                                />
                            }
                        </>
                    }
                </>
            </Modal>
    )
}

const mapStateToProps = (state: models.GlobalState) => ({
    userData: userDataSelector(state),
    showModalChat: showModalChatSelector(state)
})

const mapDispatchToProps = {
    toggleModalChat: userActionCreators.toggleModalChat,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalChat)