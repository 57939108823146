import { Button, Modal } from "antd";
import { COST_TYPE, MESSAGES, PROP, RESPONSE_CODE } from "../../utils/constants";
import { connect } from "react-redux";
import { userActionCreators } from "../../redux/actions/user";
import { useRef, useState } from "react";
import { IMAGES } from "../../assets";
import { formatDecimal, getSymbolBalance, replaceCost } from "../../utils/common";
import { showNotification } from "../../utils/notification";
import { depositApi, withdrawApi } from "../../apis/deposit";

type Props = {
    showModal: boolean;
    onClose: () => void;
}

const ModalWithdraw = (props: Props) => {
    const { showModal, onClose } = props;
    const [costType, setCostType] = useState<number>(COST_TYPE.VN)
    const [inputBalanceVN, setInputBalanceVN] = useState('')
    const [inputBalanceJP, setInputBalanceJP] = useState('')
    const inputBalanceVNRef = useRef<HTMLInputElement>(null)
    const inputBalanceJPRef = useRef<HTMLInputElement>(null)
    const [isPress, setIsPress] = useState(false)

    const handleSelectCostType = (type: number) => () => {
        setCostType(type)
    }

    const handleClickBalanceVN = () => {
        inputBalanceVNRef.current?.focus();
    }

    const handleClickBalanceJP = () => {
        inputBalanceJPRef.current?.focus();
    }

    const balance = costType === COST_TYPE.VN ? replaceCost(inputBalanceVN) : replaceCost(inputBalanceJP)

    const handleWithdraw = async () => {
        try {
            if (!isPress) {
                if(!balance) return showNotification('Số tiền không được để trống', RESPONSE_CODE.ERROR)
                setIsPress(true);
                const response = await withdrawApi({ balance, balance_type: costType})
                setIsPress(false)
                showNotification(response.message, response.code)
                if (response.code === RESPONSE_CODE.SUCCESS) {
                    onClose();
                }
            }
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
            setIsPress(false)
        }
    }

    return (
        <Modal
            {...PROP.MODAL}
            open={showModal}
            onCancel={onClose}
        >
            <div className="flex align-center mgt" onClick={handleClickBalanceVN}>
                <input
                    type="radio"
                    checked={costType === COST_TYPE.VN}
                    className="mgr-2"
                    onClick={handleSelectCostType(COST_TYPE.VN)}
                />
                <div className="input-deposit-container">
                    <img
                        className="flag-img"
                        alt="flag"
                        src={IMAGES.VN_FLAG}
                    />
                    <input
                        className="input-deposit"
                        ref={inputBalanceVNRef}
                        onFocus={handleSelectCostType(COST_TYPE.VN)}
                        value={inputBalanceVN}
                        onChange={({ target: { value } }) => setInputBalanceVN(formatDecimal(value))}
                    />
                    <p className="bold">đ</p>
                </div>
            </div>
            <div className="flex align-center mgt">
                <input
                    type="radio"
                    checked={costType === COST_TYPE.JP}
                    className="mgr-2"
                    onClick={handleSelectCostType(COST_TYPE.JP)}
                />
                <div className="input-deposit-container" onClick={handleClickBalanceJP}>
                    <img
                        className="flag-img"
                        alt="flag"
                        src={IMAGES.JP_FLAG}
                    />
                    <input
                        className="input-deposit"
                        ref={inputBalanceJPRef}
                        onFocus={handleSelectCostType(COST_TYPE.JP)}
                        value={inputBalanceJP}
                        onChange={({ target: { value } }) => setInputBalanceJP(formatDecimal(value))}
                    />
                    <p className="bold">¥</p>
                </div>
            </div>
            <div className="item-center mgt-3" onClick={handleWithdraw}>
                <Button className="outline">Xác nhận rút tiền</Button>
            </div>
        </Modal>
    )
}

export default connect(undefined)(ModalWithdraw)